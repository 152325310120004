import React, { useEffect, useState, useContext, useCallback } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button, Divider, Grid } from "@material-ui/core";
import useStyles from "../../../components/styles/styles";
import { MainContext } from "../../../context/MainContext";
import { useParams } from "react-router-dom";
import Api from "../../../constant/Api";
import { useHistory } from "react-router-dom";
import { putRequest } from "../../../constant/ApiService";
import { toast } from "react-toastify";
import Input from "../../../components/utils/Input";

const DetailOperatorCustomer = props => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  let customerData = props.location.state;
  const [customerDatas, setCustomerDatas] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    companyName: "",
    companyNatinalId: "",
  });

  useEffect(() => {
    if (customerData) {
      setCustomerDatas({
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        phone: customerData.phone,
        companyNatinalId: customerData.companyNatinalId,
      });
    }
  }, [customerData]);

  const confirmCustomerFunc = () => {
    if (!customerDatas.firstName) {
      toast.error("نام را وارد کنید");
      return;
    }
    if (!customerDatas.lastName) {
      toast.error("نام خانوادگی را وارد کنید");
      return;
    }
    if (!customerDatas.phone) {
      toast.error("شماره همراه را وارد کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(customerDatas.phone)) {
      toast.error("شماره همراه را درست وارد  کنید");
      return;
    }
    if (
      customerDatas.companyNatinalId &&
      !/^[0-9]{10}$/.test(customerDatas.companyNatinalId)
    ) {
      toast.error("شناسه ملی شرکت را درست وارد کنید");
      return;
    }

    let body = {
      id: id,
      fristName: customerDatas.firstName,
      lastName: customerDatas.lastName,
      nationl_Id: customerDatas.companyNatinalId,
      stauts: 0,
      phone: customerDatas.phone,
      work_Status: 0,
    };
    putRequest(`${Api.UpdateOperatorCustomer}/${id}`, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/operatorCustomer");
      }
    });
  };
  return (
    <Grid container>
      <PageTitle title="جزییات مشتری " />
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>نام</label>
        <Input
          name="firstName"
          value={customerDatas.firstName}
          onChange={({ target }) =>
            setCustomerDatas(state => ({ ...state, firstName: target.value }))
          }
          type="text"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>نام خانوادگی </label>
        <Input
          name="lastName"
          value={customerDatas.lastName}
          onChange={({ target }) =>
            setCustomerDatas(state => ({ ...state, lastName: target.value }))
          }
          type="text"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>شماره همراه</label>
        <Input
          name="phone"
          value={customerDatas.phone}
          onChange={({ target }) =>
            setCustomerDatas(state => ({ ...state, phone: target.value }))
          }
          type="number"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>شناسه ملی شرکت</label>
        <Input
          name="companyNatinalId"
          value={customerDatas.companyNatinalId}
          onChange={({ target }) =>
            setCustomerDatas(state => ({
              ...state,
              companyNatinalId: target.value,
            }))
          }
          type="number"
          variant="outline"
        />
      </Grid>

      <Grid container justifyContent="space-between" direction="row-reverse">
        <Grid style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={confirmCustomerFunc}
            // disabled={disabled}
          >
            ثبت{" "}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DetailOperatorCustomer;
