import React, { useState, useEffect, useContext } from "react";
import { Grid, TextareaAutosize } from "@material-ui/core";
import Input from "../../../../components/utils/Input";
import DropDown from "../../../../components/utils/DropDown";
import useStyles from "../../../../components/styles/styles";
import { getRequest } from "../../../../constant/ApiService";
import Api from "../../../../constant/Api";
import { MainContext } from "../../../../context/MainContext";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const DetailCargo = ({
  setIsCheckedSelect,
  cargoLoadingType,
  setCargoLoadingType,
}) => {
  const classes = useStyles();
  let {
    detail,
    setDetail,
    detailCargo,
    setDetailCargo,
    handleCargoWeight,
    carrier,
    handleCargoPackageTypes,
    weightValue,
    setWeightValue,
    packageValue,
    setPackageValue,
  } = useContext(MainContext);
  const {
    cargo,
    length,
    height,
    width,
    number,
    volume,
    status,
    value,
    numCargo,
    desTopCargo,
    insurance,
  } = detailCargo;

  const [checked, setChecked] = React.useState(new Array(4).fill(false));

  const cargoLoadingItemshandleChange = (e, position, item) => {
    let updatedChecked = checked.map((item, index) =>
      position == index ? !item : item,
    );
    setChecked(updatedChecked);
    setIsCheckedSelect([]);
    updatedChecked.forEach((element, index) => {
      if (element) {
        var checkbox = cargoLoadingType[index];
        setIsCheckedSelect(checkbox);
      }
    });
  };

  useEffect(() => {
    getRequest(Api.GetCargoLoadingTypes).then(res => {
      setCargoLoadingType(res && res.data.lists);
    });
  }, []);

  useEffect(() => {
    if (detail) {
      setDetailCargo({
        ...detailCargo,
        cargo: detail.title,
        value: detail.insuranceAmount,
        numCargo: detail.vehicleCount,
        height: detail.height,
        width: detail.width,
        length: detail.length,
        number: detail.number,
        volume: detail.volume,
        status: detail.status,
      });
      let defaultWeight =
        carrier.cargoWeight.length > 0 &&
        carrier.cargoWeight.filter(x => x.value == detail.weight)[0];
      setWeightValue(defaultWeight);
      let defaultPackage =
        carrier.packageTypes.length > 0 &&
        carrier.packageTypes.filter(x => x.value == detail.packageType.id)[0];
      setPackageValue(defaultPackage);

      let checkItems = checked;

      if (detail.cargoLoadingTypes && detail.cargoLoadingTypes.length > 0) {
        detail.cargoLoadingTypes.forEach(item => {
          checkItems[item.id - 1] = true;
        });
        setChecked(new Array(4).fill(false));
        setTimeout(() => {
          setChecked(checkItems);
        }, 1000);
        setIsCheckedSelect(detail.cargoLoadingTypes);
      }
    }
  }, [detail]);

  useEffect(() => {
    handleCargoWeight();
    handleCargoPackageTypes();
  }, []);
  return (
    <Grid container>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>بار</label>
        <Input
          onChange={({ target }) =>
            setDetailCargo(state => ({
              ...state,
              cargo: target.value,
            }))
          }
          value={cargo}
          type="text"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>وزن محموله</label>
        <Select
          onChange={e => {
            setWeightValue(e);
          }}
          options={carrier.cargoWeight}
          value={weightValue}
          placeholder="وزن محموله را انتخاب کنید"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>نوع بسته بندی</label>
        <Select
          onChange={e => setPackageValue(e)}
          options={carrier.packageTypes}
          value={packageValue}
          placeholder="نوع بسته بندی را انتخاب کنید"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>تعداد محموله</label>
        <Input
          onChange={({ target }) =>
            setDetailCargo(state => ({
              ...state,
              numCargo: target.value,
            }))
          }
          value={numCargo}
          type="number"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label> محموله</label>
        <Input
          onChange={({ target }) =>
            setDetailCargo(state => ({
              ...state,
              length: target.value,
            }))
          }
          value={length}
          type="number"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>عرض محموله</label>
        <Input
          onChange={({ target }) =>
            setDetailCargo(state => ({
              ...state,
              width: target.value,
            }))
          }
          value={width}
          type="number"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>طول محموله</label>
        <Input
          onChange={({ target }) =>
            setDetailCargo(state => ({
              ...state,
              height: target.value,
            }))
          }
          value={height}
          type="number"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>حجم محموله</label>
        <Input
          onChange={({ target }) =>
            setDetailCargo(state => ({
              ...state,
              volume: target.value,
            }))
          }
          value={volume}
          type="number"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>ابعاد محموله</label>
        <Input
          onChange={({ target }) =>
            setDetailCargo(state => ({
              ...state,
              number: target.value,
            }))
          }
          value={number}
          type="number"
        />
      </Grid>
      {/* <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>وضعیت محموله</label>
        <Input
          onChange={({ target }) =>
            setDetailCargo(state => ({
              ...state,
              status: target.value,
            }))
          }
          value={status}
          type="number"
        />
      </Grid> */}

      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>ارزش بیمه (میلیون تومان)</label>
        <Input
          onChange={({ target }) =>
            setDetailCargo(state => ({
              ...state,
              value: target.value,
            }))
          }
          value={value}
          type="number"
        />
      </Grid>
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
          style={{ display: "flex", flexDirection: "row" }}
        >
          {/* <label>روباری</label> */}
          {cargoLoadingType &&
            cargoLoadingType.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checked[index]}
                    onChange={e =>
                      cargoLoadingItemshandleChange(e, index, item)
                    }
                  />
                }
                label={item.title}
              />
            ))}
        </Grid>
        {/* <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>توضیحات روباری</label>
          <TextareaAutosize
            style={{
              border: "solid 1px #dcdcdc",
              height: 78,
              borderRadius: "5px",
            }}
            value={desTopCargo}
            onChange={({ target }) =>
              setDetailCargo(state => ({
                ...state,
                desTopCargo: target.value,
              }))
            }
            rows={4}
            cols={4}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default DetailCargo;
