import { Grid, Button } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "../../../components/styles/styles";
import Input from "../../../components/utils/Input";
import Api from "../../../constant/Api";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../../constant/ApiService";
import { toast } from "react-toastify";
import PageTitle from "../../../components/PageTitle/PageTitle";

const NewSocialMediaOrder = () => {
  const classes = useStyles();
  const history = useHistory();
  const [NewContent, setNewContent] = useState({
    content: "",
  });

  const addNewContent = () => {
    if (!NewContent.content) {
      toast.error("مشخصات بار را وارد کنید");
      return;
    }
 
    let body = {
      content: NewContent.content,
    };
    postRequest(Api.NewSocialMediaOrder, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        //history.push("/app/comments");
      }
    });
  };
  return (
    <Grid>
      <PageTitle title=" ثبت بار سوشال مدیا" />
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>محتوای بار </label>
          <Input
            name="content"
            value={NewContent.content}
            onChange={({ target }) =>
            setNewContent(state => ({
                ...state,
                content: target.value,
              }))
            }
            type="text"
          />
      </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        justifyContent="space-between"
        direction="row-reverse"
      >
        <Button
          variant="contained"
          style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
          onClick={addNewContent}
        >
          ثبت{" "}
        </Button>
      </Grid>
    </Grid>
  );
};
export default NewSocialMediaOrder;
