import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import TransactionsWaiting from "../../pages/order/waiting/TransactionsWaiting";
import DetailWaiting from "../../pages/order/waiting/DetailWaiting";
// context
import { useLayoutState } from "../../context/LayoutContext";
import Dashboard from "../../pages/Dashboard";
import Transactions from "../../pages/order/inProgressTransactions/Transactions";
import TransactionsCancel from "../../pages/order/TransactionsCancel";
import FinishedTransaction from "../../pages/order/finishTransaction/FinishedTransaction";
import TransactionsDetail from "../../pages/order/TransactionsDetail";
import FirmCustomer from "../../pages/customer/firmCustomer/FirmCustomer";
import IndividualCustomer from "../../pages/customer/individualCustomer/IndividualCustomer";
import OpratorCustomer from "../../pages/customer/operatorCustomer/OperatorCustomer";
import DetailIndividualCustomer from "../../pages/customer/individualCustomer/DetailIndividualCustomer";
import DetailFirmCustomer from "../../pages/customer/firmCustomer/DetailFirmCustomer";
import NewCustomer from "../../pages/customer/NewCustomer";
import DriversList from "../../pages/driver/DriversList";
import DetailOperatorCustomer from "../../pages/customer/operatorCustomer/DetailOperatorCustomer";
import TransportationCompanyList from "../../pages/transportationCompanyCompany/TransportationCompanyList";
import DetailTransportationCompany from "../../pages/transportationCompanyCompany/DetailTransportationCompany";
import NewTransportation from "../../pages/transportationCompanyCompany/NewTransportation";
import AboutUs from "../../pages/AboutUs";
import Rule from "../../pages/Rule";
import Comments from "../../pages/comments/Comments";
import NewComment from "../../pages/comments/NewComment";
import DetailDriver from "../../pages/driver/DetailDriver";
import NewOrder from "../../pages/order/newOrder/NewOrder";
import NewSocialMediaOrder from "../../pages/order/NewSocialMediaOrder/NewSocialMediaOrder";
import SocialMediaOrder from "../../pages/order/NewSocialMediaOrder/SocialMediaOrder";
import CargoAddressInfo from "../../pages/order/newOrder/CargoAddressInfo";
import DetailCargo from "../../pages/order/newOrder/DetailCargo";
import TruckType from "../../pages/order/newOrder/TruckType";
import CarrierType from "../../pages/order/newOrder/CarrierType";
import PreviewInfo from "../../pages/order/newOrder/PreviewInfo";
import NotFound from "../../pages/notFound/NotFound";
import InProgressTransactionDetail from "../../pages/order/inProgressTransactions/InProgressTransactionDetail";
import InProgressTransactionEdit from "../../pages/order/inProgressTransactions/InProgressTransactionEdit";
import NewCrmUser from "../../pages/auth/account/NewCrmUser";
import CrmUser from "../../pages/auth/account/CrmUser";
import MotesaddiCargos from "../../pages/order/motesaddi/MotesaddiCargos";




function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <Switch>
          <Route exact path="/app/dashboard" component={Dashboard} />
          <Route
            path="/app/motesaddiCargos"
            component={MotesaddiCargos}
          />
          <Route
            path="/app/transactionsWaiting"
            component={TransactionsWaiting}
          />
          <Route path="/app/transactions" component={Transactions} />
          <Route
            path="/app/inProgressTransactionDetail/:id"
            component={InProgressTransactionDetail}
          />

          <Route path="/app/inProgressTransactionEdit/:id" component={InProgressTransactionEdit} />

          <Route
            path="/app/finishedTransaction"
            component={FinishedTransaction}
          />

          <Route
            path="/app/transactionsCancel"
            component={TransactionsCancel}
          />
          <Route path="/app/detailWaiting/:id" component={DetailWaiting} />

          <Route
            path="/app/transactionsDetail/:id"
            component={TransactionsDetail}
          />
          <Route path="/app/firmCustomer" component={FirmCustomer} />
          <Route
            path="/app/individualCustomer"
            component={IndividualCustomer}
          />
          <Route path="/app/operatorCustomer" component={OpratorCustomer} />
          <Route
            path="/app/detailIndividualCustomer/:id"
            component={DetailIndividualCustomer}
          />
          <Route
            path="/app/detailFirmCustomer/:id"
            component={DetailFirmCustomer}
          />
          <Route
            path="/app/detailOperatorCustomer/:id"
            component={DetailOperatorCustomer}
          />
          <Route path="/app/newCustomer" component={NewCustomer} />
          <Route path="/app/driversList" component={DriversList} />
          <Route path="/app/detailDriver" component={DetailDriver} />
          <Route
            path="/app/transportationCompany"
            component={TransportationCompanyList}
          />
          <Route
            path="/app/detailTransportationCompany/:id"
            component={DetailTransportationCompany}
          />
          <Route path="/app/newTransportation" component={NewTransportation} />
          <Route path="/app/aboutUs" component={AboutUs} />
          <Route path="/app/rule" component={Rule} />
          <Route path="/app/comments" component={Comments} />
          <Route path="/app/newComment" component={NewComment} />
          <Route path="/app/newOrder" component={NewOrder} />
          <Route path="/app/newSocialMediaOrder" component={NewSocialMediaOrder} />
          <Route path="/app/SocialMediaOrder" component={SocialMediaOrder} />
          <Route path="/app/cargoAddressInfo" component={CargoAddressInfo} />
          <Route path="/app/detailCargo" component={DetailCargo} />
          <Route path="/app/truckType" component={TruckType} />
          <Route path="/app/carrierType" component={CarrierType} />
          <Route path="/app/previewInfo" component={PreviewInfo} />
          <Route path="/app/newCrmUser" component={NewCrmUser} />
          <Route path="/app/CrmUser" component={CrmUser} />

        </Switch>
      </div>
    </div>
  );
}

export default withRouter(Layout);
