import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PageTitle from "../../components/PageTitle";
import Api from "../../constant/Api";
import { getRequest } from "../../constant/ApiService";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import { MainContext } from "../../context/MainContext";

const TransactionsCancel = () => {
  const [items, setItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  let {
    handleChangeRowsPerPage,
    handleChangePage,
    openPopup,
    setOpenPopup,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
  } = useContext(MainContext);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getRequest(Api.GetCanceledCargoes).then(res => {
      setItems(res && res.data.lists);
    });
  }, []);

  return (
    <Grid>
      <PageTitle title="بارهای رد شده" />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 150 }}>ردیف</TableCell>
              <TableCell style={{ minWidth: 150 }}>شناسه</TableCell>
              <TableCell style={{ minWidth: 150 }}>مشخصات مشتری</TableCell>
              <TableCell style={{ minWidth: 200 }}>
                مشخصات تحویل گیرنده
              </TableCell>
              <TableCell>بار</TableCell>
              <TableCell style={{ minWidth: 150 }}>نوع کامیون</TableCell>
              <TableCell style={{ minWidth: 200 }}>
                تاریخ و ساعت بارگیری
              </TableCell>
              <TableCell style={{ minWidth: 200 }}>
                تاریخ و ساعت ثبت سفارش
              </TableCell>
              <TableCell>بار نامه</TableCell>
              <TableCell>علت کنسلی</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.length > 0 &&
              items.map((item, index) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    history.push({
                      pathname: `/app/transactionsDetail/${item.id}`,
                      state: { detailData: "cancelData" },
                    });
                  }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    {item.cargoOwnerName}
                    <br />
                    {item.cargoOwnerPhoneNumber}
                  </TableCell>
                  <TableCell>
                    {item.transfereeName}
                    <br />
                    {item.transfereePhoneNumber}
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.truckTypeName}</TableCell>
                  <TableCell>{item.persianLoadingDateTime}</TableCell>
                  <TableCell>{item.persianCreated}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{item.description}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage=""
        rowsPerPageOptions={[]}
        component="div"
        count={items && items.length > 0 ? items.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
};

export default TransactionsCancel;
