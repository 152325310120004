import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Divider,
  Grid,
  Modal,
  TextareaAutosize,
} from "@material-ui/core";
import Input from "../../../components/utils/Input";
import useStyles from "../../../components/styles/styles";
import Api from "../../../constant/Api";
import Axios from "axios";


const BarnameModal = ({ open, setOpen, barId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [wayBillData, setWayBillData] = useState({
    wayBill: "",
    barId: barId
  });
  const {wayBill} = wayBillData;
console.log(barId,"barId")
  const handleClose = () => {
    setOpen(false);
  };

  const confirm = async () => {
    try{
      const formData = new FormData();
     
      // Update the formData object
      console.log(selectedFile.name)

      // var file = new Blob([selectedFile], {type: 'application/pdf'});

      formData.append(
        "FormFile",
        selectedFile
      );
      formData.append("WayBill", wayBill)
      formData.append("BarId", barId)


      console.log('wayBill', wayBill)
      console.log('bar', barId);
      console.log('formData', formData);
      const body = {
        wayBill: wayBill,
        barId: barId,
        formFile: formData
      }
  
      console.log(body)

  
      const response = await Axios(Api.SetWayBill, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        },
        data: formData,
      })
      .then(response => {
        if (response.ok) {
                setOpen(false)
        }
      }).catch(console.error)
      //const data=await response.json()
      setOpen(false)

    }catch(e){
      console.log(e)
    }
  
  };


   
  // On file select (from the pop up)
  var onFileChange = event => {
    // Update the state
    setSelectedFile(event.target.files[0]);
   
  };

  console.log(selectedFile)
  return (
    <Modal open={open}>
      <Grid container className={classes.newAddressModal}>
        <h3>
          ثبت بارنامه
          <Divider style={{ width: "100%", margin: "10px 0 0" }} />
        </h3>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            style={{ display: "flex", flexDirection: "row" }}
            item
            xl={12}
            className={classes.firstFiled}
          >
            <h4> {`باربری : ${'سوربن ترابر'}`}</h4>
          </Grid>

          <Grid className={classes.colItem} item sm={12} xs={12}>
            <label>شماره بارنامه</label>
            <Input
              onChange={({ target }) =>
              setWayBillData(state => ({
                ...state,
                wayBill: target.value,
              }))
               }
              value={wayBill}
              type="number"
            />
          </Grid>

          <Grid className={classes.colItem} item sm={12} xs={12}>
            <label>انتخاب فایل بارنامه</label>
            <Input type="file" onChange={onFileChange} />
          </Grid>
        
        </Grid>


        <Grid
          container
          justifyContent="flex-end"
          style={{ paddingTop: "25px" }}
        >
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            style={{
              backgroundColor: "inherit",
              color: "inherit",
              width: 110,
              marginLeft: 10,
            }}
          >
            انصراف
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#3CD4A0", color: "#fff", width: 110 }}
            onClick={()=>confirm()}
          >
            تایید
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default BarnameModal;
