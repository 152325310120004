import React, { useState, useContext, useEffect } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";
import { Grid, TextareaAutosize, Button, Divider } from "@material-ui/core";
import { STEPS } from "../../../constant/staticData";
import Select from "react-select";
import useStyles from "../../../components/styles/styles";
import { useHistory } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import DatePicker from "react-modern-calendar-datepicker";
import Input from "../../../components/utils/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { MainContext } from "../../../context/MainContext";
import StepButton from "@material-ui/core/StepButton";
import { toast } from "react-toastify";

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CarrierType = () => {
  const classes = useStyles();
  const history = useHistory();

  let {
    newOrder,
    setNewOrder,
    activeStep,
    setActiveStep,
    handleStep,
  } = useContext(MainContext);

  const confirmCarrierType = () => {
    if (!newOrder.loadingTypeNight && !newOrder.loadingTypeTeraffic) {
      toast.error("عنوان بارگیر را وارد کنید");
      return;
    }

    if (!newOrder.description) {
      toast.error("توضیحات را وارد کنید");
      return;
    }
    history.push("/app/previewInfo");
  };

  const handleChangeSwitch = event => {
    setNewOrder({ ...newOrder, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setActiveStep(4);
  }, []);

  window.onload = e => {
    if (performance.navigation.type == 1) {
      history.push("/app/newOrder");
    }
  };

  return (
    <Grid>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map((label, index) => (
          <Step key={label}>
            <StepButton>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <PageTitle title="نوع بارگیر" />

      <Grid container>
        <FormControlLabel
          control={
            <IOSSwitch
              checked={newOrder.loadingTypeNight}
              onChange={handleChangeSwitch}
              name="loadingTypeNight"
            />
          }
          label="شب بارگیری"
        />
      </Grid>
      <Grid container>
        <FormControlLabel
          control={
            <IOSSwitch
              checked={newOrder.loadingTypeTeraffic}
              onChange={handleChangeSwitch}
              name="loadingTypeTeraffic"
            />
          }
          label="ترافیکی"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>توضیحات</label>
        <TextareaAutosize
          style={{ border: "solid 1px #dcdcdc", height: 78 }}
          value={newOrder.description}
          onChange={({ target }) =>
            setNewOrder(state => ({
              ...state,
              description: target.value,
            }))
          }
          rows={4}
          cols={4}
        />
      </Grid>
      <Grid container justifyContent="space-between" direction="row-reverse">
        <Grid style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={confirmCarrierType}
            //   disabled={disabled}
          >
            مرحله بعد
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CarrierType;
