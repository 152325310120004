import React, { useEffect, useState, useContext, useCallback } from "react";

import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Divider,
  Modal,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import { MainContext } from "../../context/MainContext";
import PageTitle from "../../components/PageTitle/PageTitle";
import Api from "../../constant/Api";
import { useHistory } from "react-router-dom";
import { getRequest } from "../../constant/ApiService";
import { useParams } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useStyles from "../../components/styles/styles";
import Input from "../../components/utils/Input";

const Comments = () => {
  const [items, setItems] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [toggleModal, setToggleModal] = useState(false);
  const [commentSearch, setCommentSearch] = useState({
    userId: "",
  });
  let {
    handleChangeRowsPerPage,
    openPopup,
    setOpenPopup,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    detailCustomer,
    setDetailCustomer,
  } = useContext(MainContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getCommentList = () => {
    getRequest(
      `${Api.GetComment}?UserId=${commentSearch.userId}&PageNumber=${page +
        1}&PageSize=${rowsPerPage}`,
    ).then(res => {
      setItems(res && res.data);
    });
  };
  useEffect(() => {
    getCommentList();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getCommentList();
  };
  return (
    <Grid>
      <PageTitle title="لیست  نظرات" />
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>شناسه کاربر </label>
          <Input
            name="userId"
            value={commentSearch.userId}
            onChange={({ target }) =>
              setCommentSearch(state => ({
                ...state,
                userId: target.value,
              }))
            }
            type="number"
          />
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          direction="row-reverse"
          style={{ padding: "20px 0" }}
        >
          <Grid style={{ display: "flex" }}>
            <Button
              variant="contained"
              style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
              onClick={getCommentList}
            >
              جستجو کنید
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>ردیف</TableCell>
              <TableCell>شناسه</TableCell>
              <TableCell> نام کاربر</TableCell>
              <TableCell>نوع مشتری</TableCell>
              <TableCell>عنوان</TableCell>
              <TableCell>توضیحات</TableCell>
              <TableCell>تاریخ ارسال </TableCell>
              <TableCell>اقدام </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {items &&
              items.lists &&
              items.lists.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.userFullName}</TableCell>
                  <TableCell>{item.userTypeName}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.createDateTime}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: "20ch",
                        },
                      }}
                    >
                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="primary"
                          onClick={() => setToggleModal(true)}
                        >
                          ارسال پاسخ
                        </Button>
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage=""
        rowsPerPageOptions={[]}
        component="div"
        count={items && items.count > 0 ? items.count : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal open={toggleModal}>
        <Grid container className={classes.newAddressModal}>
          <PageTitle title=" پاسخ نظر" />

          <TextareaAutosize
            maxRows={300}
            style={{ width: 400, marginBottom: "20px" }}
          />
          <Grid container>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#3CD4A0",
                color: "#fff",
                width: 110,
                marginLeft: 10,
              }}
            >
              تایید
            </Button>

            <Button
              onClick={() => setToggleModal(false)}
              variant="contained"
              style={{
                backgroundColor: "inherit",
                color: "inherit",
                width: 110,
                marginLeft: 10,
              }}
            >
              انصراف
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};
export default Comments;
