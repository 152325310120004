export const BASEURL = "https://apis.barmap.ir";
//export const BASEURL = "http://localhost:8002";

export default {
  /////////////////LOGIN/////////////////////////////////////////////////////////////////////////
  LoginByEmailUserName: BASEURL + "/api/Admin/Account/LoginByEmailUserName",
  /////////////////TRANSCTIONS//////////////////////////////////////////////////////////////////
  GetTransactionsWaiting:
    BASEURL + "/api/Admin/Cargo/GetWaitingConfirmationCargoes",
  GetInProgressCargoes: BASEURL + "/api/Admin/Cargo/GetInProgressCargoes",
  GetDoneCargoes: BASEURL + "/api/Admin/Cargo/GetDoneCargoes",
  GetCanceledCargoes: BASEURL + "/api/Admin/Cargo/GetCanceledCargoes",
  GetPackageTypes: BASEURL + "/api/Admin/Cargo/GetPackageTypes",
  GetWeights: BASEURL + "/api/Admin/Cargo/GetWeights",
  GetCargoLoadingTypes: BASEURL + "/api/Admin/Cargo/GetCargoLoadingTypes",
  GetCargoDetail: BASEURL + "/api/Admin/Cargo/GetCargoDetail",
  GetCarrierTypes: BASEURL + "/api/Admin/Cargo/GetCarrierTypes",
  GetConfirmCargo: BASEURL + "/api/Admin/Cargo/ConfirmCargo",
  UpdateCargo: BASEURL + "/api/Admin/Cargo/UpdateCargo",
  CreateCargoAddress: BASEURL + "/api/Admin/Cargo/CreateCargoAddress",
  DeleteCargo: BASEURL + "/api/Admin/Cargo/Delete",
  DeleteCargoAddress: BASEURL + "/api/Admin/Cargo/DeleteCargoAddress",
  GetDriversOfCargo: BASEURL + "/api/Admin/Cargo/GetDriversOfCargo",
  DriverApprovedToCargo:BASEURL + "​/api​/Admin​/Cargo​/DriverApprovedToCargoCommand",
  ChangeCancelCargoToAnotherState:BASEURL + "/api/Admin/Cargo/ChangeCancelCargoToAnotherState",
  GetTruckTypes: BASEURL + "/api/Admin/Cargo/GetTruckTypes",
  GetCargoLoadingTypes: BASEURL + "/api/Admin/Cargo/GetCargoLoadingTypes",
  CreateCargo: BASEURL + "/api/Admin/Cargo/Create",
  SetWayBill: BASEURL + "/api/App/Cargo/SetWayBill",
  ChangeStateCargo: BASEURL + "/api/Admin/Cargo/GetCargoStates",
  GetDriversNotBookCargo: BASEURL + "/api/Admin/Cargo/GetDriversNotBookCargo",
  DriverCargoReserveByAdmin: BASEURL + "/api/Admin/Cargo/DriverCargoReserveByAdmin",
  NewSocialMediaOrder: BASEURL + "/api/Admin/Cargo/CreateSocialMediaCargo",
  CreateNewCargo: BASEURL + "/api/Admin/Cargo/CreateNewCargo",
  GetDashboardReport: BASEURL + "/api/Admin/Report/GetDashboardReport",





  /////////////CUSTOMER//////////////////////////////////////////////////////////////////////////
  GetCargoOwnersNaturalList:
    BASEURL + "/api/Admin/CargoOwner/GetCargoOwnersNatural_List",
  UpdateNaturalCustomer: BASEURL + "/api/Admin/CargoOwner/UpdateNatural",
  GetCargoOwnersLegalList:
    BASEURL + "/api/Admin/CargoOwner/GetCargoOwnersLegal_List",
  UpdateLegalCustomer: BASEURL + "/api/Admin/CargoOwner/UpdateLegal",
  GetCargoOwnersOperatorList:
    BASEURL + "/api/Admin/CargoOwner/GetCargoOwnersOperator_List",
  UpdateOperatorCustomer: BASEURL + "/api/Admin/CargoOwner/UpdateOperator",
  CreateNaturalCustomer: BASEURL + "/api/Admin/CargoOwner/CreateNatural",
  CreateLegalCustomer: BASEURL + "/api/Admin/CargoOwner/CreateLegal",
  CreateOperatorCustomer: BASEURL + "/api/Admin/CargoOwner/CreateOperator",
  ////////////////DRIVERS//////////////////////////////////////////////////////////////////////////
  GetDriversInfoList: BASEURL + "/api/Admin/Driver/GetDriversInfo_List",
  CreateDriver: BASEURL + "/api/Admin/Driver/CreateDriver",
  CreateDriverByNavy: BASEURL + "/api/Admin/Driver/CreateDriverByNavy",
  CreateDriverAndNavy: BASEURL + "/api/Admin/Driver/CreateDriverAndNavy",
  ////////////////TRANSACTIONCompany///////////////////////////////////////////////////////////////
  GetTransportationCompanyList:
    BASEURL + "/api/Admin/TransportationCompany/GetTransportationCompany_List",
  UpdateTransportationCompany:
    BASEURL + "/api/Admin/TransportationCompany/UpdateTransportationCompany",
  CreateTransportationCompany:
    BASEURL + "/api/Admin/TransportationCompany/CreateTransportationCompany",
  //////////ABOUTUS////////////////////////////////////////////////////////////////////////////////
  GetAboutUs: BASEURL + "/api/Common/About/GetAboutUs",
  CreateAboutUs: BASEURL + "/api/Common/About/CreateAboutUs",
  //////////RULE//////////////////////////////////////////////////////////////////////////////////
  GetRule: BASEURL + "/api/Common/Rule/GetRule",
  CreateRule: BASEURL + "/api/Common/Rule/CreateRule",
  //////////COMMENT//////////////////////////////////////////////////////////////////////////////////
  GetComment: BASEURL + "/api/Common/Comment/GetComments_List",
  CreateComment: BASEURL + "/api/Common/Comment/CreateComment",
  //////////CITY/////////////////////////////////////////////////////////////////////////////////////
  GetCity: BASEURL + "/api/Common/City/GetCities",
    //////////CRMUSER/////////////////////////////////////////////////////////////////////////////////////
    RegisterCrmUser: BASEURL + "/api/Admin/Account/RegisterCrmUser",
    GetCrmUser: BASEURL + "/api/Admin/Account/GetCrmUser",
    GetRoles: BASEURL + "/api/Admin/Roles/GetAllRoles",
};
