import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Divider,
  Grid,
  Modal,
} from "@material-ui/core";
import Select from "react-select";
import useStyles from "../../../components/styles/styles";
import Api from "../../../constant/Api";
import { toast } from "react-toastify";
import { getRequest, putRequest } from "../../../constant/ApiService";




const CargoDriversModel = ({ open, setOpen ,driverOfCargoData,barId}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [driversList, setDriversList] = useState([]);
  const [selectedCargoDriver, setSelectedCargoDrivers] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };



  console.log(driverOfCargoData);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      try {
        const config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          }
        }
        const response = await fetch(`${Api.GetDriversNotBookCargo}?BarId=${barId}`, config);
        const data = await response.json();
        const modifiedData = data.data.lists.map((item) => {
          return {
            value: item.id,
            label: item.firstName + ' ' + item.lastName
          }
        })
        setDriversList(modifiedData);
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
      
    };

    loadData();
  }, []);

  const selectCargoDrivers = e => setSelectedCargoDrivers(e);


  const confirm = async () => {

    try{
      console.log(Api.DriverApprovedToCargo)
      const body = {
        barId: barId,
        driverId: selectedCargoDriver.value
      }
  
      console.log(body)  
      const response = await fetch("https://apis.barmap.ir/api/Admin/Cargo/DriverCargoReserveByAdmin", {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        },
        body: JSON.stringify(body),
      }).then(response => {
        if (response.ok) {
                setOpen(false)
        }
      }).catch(console.error)
    }catch(e){
      console.log(e)
    }  
  
  };



 
  return (
    <Modal open={open}>
      <Grid container className={classes.newAddressModal}>
        <h3>
         راننده ها
          <Divider style={{ width: "100%", margin: "10px 0 0" }} />
        </h3>
        <Grid container direction="column" wrap="nowrap">
         
        <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>  راننده ها</label>
              <Select
               onChange={(e) => selectCargoDrivers(e)}
                        options={driversList}
                        value={selectedCargoDriver} 
                //onChange={e => setCarrier({ ...carrier, carrierTypeValue: e })}
                // options={carrier.carrierType}
                // value={carrier.carrierTypeValue}
                placeholder=" راننده  را انتخاب کنید"
              />
            </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          style={{ paddingTop: "25px" }}
        >
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            style={{
              backgroundColor: "inherit",
              color: "inherit",
              width: 110,
              marginLeft: 10,
            }}
          >
            انصراف
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#3CD4A0", color: "#fff", width: 110 }}
            onClick={()=>confirm()}
          >
            تایید
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
  // return (
  //   <Modal open={open} onClose={handleClose}>
  //     <Grid container className={classes.newAddressModal}>
  //       <Select
  //         onChange={(e) => selectCargoDriver(e)}
  //         options={driversList}
  //         value={selectedCargoDriver} 
  //         style={{ marginBottom: 16 }}
  //       />
  //       <Button style={{marginTop:16}} variant="contained" onClick={()=>confirm()}>تایید</Button>
  //     </Grid>
  //   </Modal>
  // );
};

export default CargoDriversModel;
