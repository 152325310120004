import React, { useState, useContext, useEffect } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";
import { Grid, TextareaAutosize, Button, Divider } from "@material-ui/core";
import { STEPS } from "../../../constant/staticData";
import Select from "react-select";
import useStyles from "../../../components/styles/styles";
import { useHistory } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import DatePicker from "react-modern-calendar-datepicker";
import Input from "../../../components/utils/Input";
import { MainContext } from "../../../context/MainContext";
import StepButton from "@material-ui/core/StepButton";
import { toast } from "react-toastify";

const TruckType = () => {
  const classes = useStyles();
  const history = useHistory();

  let {
    carrier,
    setCarrier,
    handleTruckType,
    handleCarrierType,
    activeStep,
    setActiveStep,
    // handleStep,
  } = useContext(MainContext);

  useEffect(() => {
    handleTruckType();
    handleCarrierType();
  }, []);
  useEffect(() => {
    setCarrier({ ...carrier, truckTypeValue: "" });
    setCarrier({ ...carrier, carrierTypeValue: "" });
  }, []);

  const confirmTruckType = () => {
    if (!carrier.truckTypeValue) {
      toast.error("عنوان بارگیر را وارد کنید");
      return;
    }
    if (!carrier.carrierTypeValue) {
      toast.error(" بارگیر را وارد کنید");
      return;
    }
    if (!carrier.billOfLoadingValue) {
      toast.error("عنوان بار را وارد کنید");
      return;
    }
    history.push("/app/carrierType");
  };

  useEffect(() => {
    setActiveStep(3);
  }, []);

  window.onload = e => {
    if (performance.navigation.type == 1) {
      history.push("/app/newOrder");
    }
  };

  return (
    <Grid>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map((label, index) => (
          <Step key={label}>
            <StepButton>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <PageTitle title="نوع بارگیر" />

      <Grid container>
        <h3> بارگیر</h3>
        <Grid container>
          <Grid
            className={classes.colItem}
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <label> بارگیر</label>
            <Select
              onChange={e => setCarrier({ ...carrier, truckTypeValue: e })}
              options={carrier.truckType}
              value={carrier.truckTypeValue}
              placeholder="بارگیر را انتخاب کنید"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <label> عنوان بارگیر</label>
            <Select
              onChange={e => setCarrier({ ...carrier, carrierTypeValue: e })}
              options={carrier.carrierType}
              value={carrier.carrierTypeValue}
              placeholder="عنوان بارگیر را انتخاب کنید"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <label> سند بار</label>
            <Select
              onChange={e => setCarrier({ ...carrier, billOfLoadingValue: e })}
              options={[
                {
                  value: 1,
                  label: "بارنامه",
                },
                {
                  value: 2,
                  label: "بیجک",
                },
              ]}
              value={carrier.billOfLoadingValue}
              placeholder="عنوان بارگیر را انتخاب کنید"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" direction="row-reverse">
        <Grid style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={confirmTruckType}
            //   disabled={disabled}
          >
            مرحله بعد
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TruckType;
