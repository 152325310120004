import {createTheme, responsiveFontSizes} from "@material-ui/core/styles";
import "../../assets/shabnam-font.css";
import palette from "./palette";

let theme = createTheme({
    direction: 'rtl',
    fontFamily: "Shabnam !important",
    palette: palette
})

export default responsiveFontSizes(theme)