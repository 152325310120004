import { Grid, Button } from "@material-ui/core";
import React, { useState ,useEffect} from "react";
import Select from "react-select";
import useStyles from "../../../components/styles/styles";
import Input from "../../../components/utils/Input";
import Api from "../../../constant/Api";
import { useHistory } from "react-router-dom";
import { postRequest, getRequest } from "../../../constant/ApiService";

import { toast } from "react-toastify";
import PageTitle from "../../../components/PageTitle/PageTitle";

const NewCrmUser = () => {
  const classes = useStyles();
  const history = useHistory();

  const [roleUser, setRoleUser] = useState({});

  const [NewCrmUserData, setNewCrmUserData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    nationalId: "",
    userName: "",
    email: "",
    password: "",
    roleId: "",
  });

  const roleUserOption = item => {
    getRequest( `${Api.GetRoles}`).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.name,
          };
        });

       console.log(handleOptions)

      setRoleUser(handleOptions);
    });
  };
 

  useEffect(() => {
    roleUserOption();
  }, []);
  const addNewCrmUser = () => {
    let body = {
      phoneNumber: NewCrmUserData.phoneNumber,
      firstName: NewCrmUserData.firstName,
      lastName: NewCrmUserData.lastName,
      nationalId: NewCrmUserData.nationalId,
      userName: NewCrmUserData.userName,
      email: NewCrmUserData.email,
      password: NewCrmUserData.password,
      roleId: NewCrmUserData.roleId,
    }
      if (!NewCrmUserData.firstName) {
        toast.error("نام را وارد کنید");
        return;
      }
      if (!NewCrmUserData.lastName) {
        toast.error("نام خانوادگی را وارد کنید");
        return;
      }

      if (
        NewCrmUserData.nationalId &&
        !/^[0-9]{10}$/.test(NewCrmUserData.nationalId)
      ) {
        toast.error("کدملی را درست وارد کنید");
        return;
      }
      if (!NewCrmUserData.phoneNumber) {
        toast.error("شماره همراه را وارد کنید");
        return;
      }

      if (!/^[0-9]{11}$/.test(NewCrmUserData.phoneNumber)) {
        toast.error("شماره همراه را درست وارد  کنید");
        return;
      }


      if (!NewCrmUserData.userName) {
        toast.error("شناسه کاربری را وارد کنید");
        return;
      }

      if (!NewCrmUserData.password) {
        toast.error("پسورد را وارد کنید");
        return;
      }

      if (!NewCrmUserData.email) {
        toast.error("ایمیل را وارد کنید");
        return;
      }

      if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(NewCrmUserData.email)) {
        toast.error("ایمیل را درست وارد کنید");
        return;
      }
      postRequest(Api.RegisterCrmUser, body).then(res => {
        if (res && res.succeeded && res.succeeded == true) {
          toast.success(res.message);
          history.push("/app/crmUser");
        }
      });
    
     
     console.log(body)
  
    }

  return (
    <Grid>
      <PageTitle title=" ثبت کاربر" />
 

        <Grid container>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>نام </label>
            <Input
              name="firstName"
              value={NewCrmUserData.firstName}
              onChange={({ target }) =>
                setNewCrmUserData(state => ({
                  ...state,
                  firstName: target.value,
                }))
              }
              type="text"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>نام خانوادگی </label>
            <Input
              name="lastName"
              value={NewCrmUserData.lastName}
              onChange={({ target }) =>
                setNewCrmUserData(state => ({
                  ...state,
                  lastName: target.value,
                }))
              }
              type="text"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>شماره همراه </label>
            <Input
              name="phoneNumber"
              value={NewCrmUserData.phoneNumber}
              onChange={({ target }) =>
                setNewCrmUserData(state => ({ ...state, phoneNumber: target.value }))
              }
              type="tel"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>شناسه ملی </label>
            <Input
              name="nationalId"
              value={NewCrmUserData.nationalId}
              onChange={({ target }) =>
                setNewCrmUserData(state => ({
                  ...state,
                  nationalId: target.value,
                }))
              }
              type="number"
            />
          </Grid>

          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>ایمیل</label>
            <Input
              name="email"
              value={NewCrmUserData.email}
              onChange={({ target }) =>
                setNewCrmUserData(state => ({
                  ...state,
                  email: target.value,
                }))
              }
              type="email"
            />
          </Grid>

          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label> شناسه کابری </label>
            <Input
              name="userName"
              value={NewCrmUserData.userName}
              onChange={({ target }) =>
                setNewCrmUserData(state => ({
                  ...state,
                  userName: target.value,
                }))
              }
              type="email"
            />
          </Grid>

          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>پسورد </label>
            <Input
              name="password"
              value={NewCrmUserData.password}
              onChange={({ target }) =>
                setNewCrmUserData(state => ({
                  ...state,
                  password: target.value,
                }))
              }
              type="password"
            />
          </Grid>
          <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نقش </label>
          <Select
            onChange={e => {
              setNewCrmUserData(prevRoleUser => {
                return {
                  ...prevRoleUser,
                  roleId: e.value,
                };
              });
            }}
            options={roleUser}
            value={roleUser.label}
            placeholder="نقش را انتخاب کنید"
          />
        </Grid>


        </Grid>

         
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent="space-between"
          direction="row-reverse"
        >
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={addNewCrmUser}
          >
            ثبت{" "}
          </Button>
        </Grid>

    </Grid>
  );
};
export default NewCrmUser;
