import React from "react";
import { Button } from "@material-ui/core";

const CustomButton = ({variant, color, txt, txtColor, onClick, margin, height}) => {
  
  return (
    <>
      <Button
        onClick={onClick}
        variant={variant}
        style={{
          backgroundColor: color,
          color: txtColor,
          width: 110,
          margin: margin,
          height: height
        }}
      >
        {txt}
      </Button>
     
    </>
  );
};

export default CustomButton;