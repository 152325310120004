import { Grid, Button } from "@material-ui/core";
import React, { useState } from "react";
import Select from "react-select";
import { TypeCustomer } from "../../constant/staticData";
import useStyles from "../../components/styles/styles";
import Input from "../../components/utils/Input";
import Api from "../../constant/Api";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../constant/ApiService";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle/PageTitle";

const NewCustomer = () => {
  const classes = useStyles();
  const history = useHistory();

  const [typeCustomer, setTypeCustomer] = useState("");
  const [NewCustomerData, setNewCustomerData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    nationlId: "",
    companyName: "",
    companyphone: "",
    companyAreaCode: "",
    companyNationalCode: "",
  });
  const TypeCustomerChange = e => {
    setNewCustomerData({
      firstName: "",
      lastName: "",
      phone: "",
      nationlId: "",
      companyName: "",
      companyphone: "",
      companyAreaCode: "",
      companyNationalCode: "",
    });
    setTypeCustomer(e.value);
  };

  const addNewCustomer = () => {
    let body = {
      phone: NewCustomerData.phone,
      fristName: NewCustomerData.firstName,
      lastName: NewCustomerData.lastName,
      security_Number: NewCustomerData.nationlId,
      type: 0,
      status: 0,
      inviter_Code: 0,
    };
    if (typeCustomer == 1) {
      if (!NewCustomerData.firstName) {
        toast.error("نام را وارد کنید");
        return;
      }
      if (!NewCustomerData.lastName) {
        toast.error("نام خانوادگی را وارد کنید");
        return;
      }

      if (
        NewCustomerData.nationlId &&
        !/^[0-9]{10}$/.test(NewCustomerData.nationlId)
      ) {
        toast.error("کدملی را درست وارد کنید");
        return;
      }
      if (!NewCustomerData.phone) {
        toast.error("شماره همراه را وارد کنید");
        return;
      }

      if (!/^[0-9]{11}$/.test(NewCustomerData.phone)) {
        toast.error("شماره همراه را درست وارد  کنید");
        return;
      }
      postRequest(Api.CreateNaturalCustomer, body).then(res => {
        if (res && res.succeeded && res.succeeded == true) {
          toast.success(res.message);
          history.push("/app/individualCustomer");
        }
      });
    } else if (typeCustomer == 2) {
      if (!NewCustomerData.companyName) {
        toast.error("نام شرکت را وارد کنید");
        return;
      }
      if (
        NewCustomerData.companyphone &&
        !/^[0-9]{8}$/.test(NewCustomerData.companyphone)
      ) {
        toast.error("شماره شرکت را درست وارد کنید");
        return;
      }
      if (
        NewCustomerData.companyAreaCode &&
        !/^[0-9]{3}$/.test(NewCustomerData.companyAreaCode)
      ) {
        toast.error("پیش شماره را درست وارد کنید");
        return;
      }
      if (
        NewCustomerData.companyNationalCode &&
        !/^[0-9]{10}$/.test(NewCustomerData.companyNationalCode)
      ) {
        toast.error("شناسه ملی شرکت را درست وارد کنید");
        return;
      }

      if (!NewCustomerData.phone) {
        toast.error("شماره همراه را وارد کنید");
        return;
      }
      if (!/^[0-9]{11}$/.test(NewCustomerData.phone)) {
        toast.error("شماره همراه را درست وارد  کنید");
        return;
      }
      let body = {
        phone: NewCustomerData.phone,
        company_Name: NewCustomerData.companyName,
        company_Tell: NewCustomerData.companyphone,
        company_AreaCode_Tell: NewCustomerData.companyAreaCode,
        company_Nationl_Id: NewCustomerData.companyNationalCode,
        type: 0,
        status: 0,
        inviter_Code: 0,
      };

      postRequest(Api.CreateLegalCustomer, body).then(res => {
        if (res && res.succeeded && res.succeeded == true) {
          toast.success(res.message);
          history.push("/app/firmCustomer");
        }
      });
    } else if (typeCustomer == 3) {
      if (!NewCustomerData.firstName) {
        toast.error("نام را وارد کنید");
        return;
      }
      if (!NewCustomerData.lastName) {
        toast.error("نام خانوادگی را وارد کنید");
        return;
      }
      if (!NewCustomerData.phone) {
        toast.error("شماره همراه را وارد کنید");
        return;
      }
      if (!/^[0-9]{11}$/.test(NewCustomerData.phone)) {
        toast.error("شماره همراه را درست وارد  کنید");
        return;
      }
      if (
        NewCustomerData.nationlId &&
        !/^[0-9]{10}$/.test(NewCustomerData.nationlId)
      ) {
        toast.error("شناسه ملی  را درست وارد کنید");
        return;
      }

      postRequest(Api.CreateOperatorCustomer, body).then(res => {
        if (res && res.succeeded && res.succeeded == true) {
          toast.success(res.message);
          history.push("/app/operatorCustomer");
        }
      });
    }
  };
  return (
    <Grid>
      <PageTitle title=" ثبت مشتری" />

      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>نوع مشتری</label>
        <Select
          onChange={TypeCustomerChange}
          options={TypeCustomer.map(item => {
            return {
              value: item.value,
              label: item.label,
            };
          })}
          placeholder="نوع مشتری را انتخاب کنید"
        />
      </Grid>
      {typeCustomer == 1 || typeCustomer == 3 ? (
        <Grid container>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>نام </label>
            <Input
              name="firstName"
              value={NewCustomerData.firstName}
              onChange={({ target }) =>
                setNewCustomerData(state => ({
                  ...state,
                  firstName: target.value,
                }))
              }
              type="text"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>نام خانوادگی </label>
            <Input
              name="lastName"
              value={NewCustomerData.lastName}
              onChange={({ target }) =>
                setNewCustomerData(state => ({
                  ...state,
                  lastName: target.value,
                }))
              }
              type="text"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>شماره همراه </label>
            <Input
              name="phone"
              value={NewCustomerData.phone}
              onChange={({ target }) =>
                setNewCustomerData(state => ({ ...state, phone: target.value }))
              }
              type="tel"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>شناسه ملی </label>
            <Input
              name="nationlId"
              value={NewCustomerData.nationlId}
              onChange={({ target }) =>
                setNewCustomerData(state => ({
                  ...state,
                  nationlId: target.value,
                }))
              }
              type="number"
            />
          </Grid>
        </Grid>
      ) : typeCustomer == 2 ? (
        <Grid container>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>نام شرکت </label>
            <Input
              name="companyName"
              value={NewCustomerData.companyName}
              onChange={({ target }) =>
                setNewCustomerData(state => ({
                  ...state,
                  companyName: target.value,
                }))
              }
              type="text"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>پیش شماره </label>
            <Input
              name="companyAreaCode"
              value={NewCustomerData.companyAreaCode}
              onChange={({ target }) =>
                setNewCustomerData(state => ({
                  ...state,
                  companyAreaCode: target.value,
                }))
              }
              type="text"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>تلفن شرکت </label>
            <Input
              name="companyphone"
              value={NewCustomerData.companyphone}
              onChange={({ target }) =>
                setNewCustomerData(state => ({
                  ...state,
                  companyphone: target.value,
                }))
              }
              type="text"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>شماره همراه </label>
            <Input
              name="phone"
              value={NewCustomerData.phone}
              onChange={({ target }) =>
                setNewCustomerData(state => ({ ...state, phone: target.value }))
              }
              type="tel"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <label>شناسه ملی </label>
            <Input
              name="companyNationalCode"
              value={NewCustomerData.companyNationalCode}
              onChange={({ target }) =>
                setNewCustomerData(state => ({
                  ...state,
                  companyNationalCode: target.value,
                }))
              }
              type="number"
            />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {typeCustomer ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent="space-between"
          direction="row-reverse"
        >
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={addNewCustomer}
          >
            ثبت{" "}
          </Button>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};
export default NewCustomer;
