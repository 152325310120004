import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Divider,
  Grid,
  Modal,
  TextareaAutosize,
} from "@material-ui/core";
import Input from "../../../../components/utils/Input";
import DropDown from "../../../../components/utils/DropDown";
import useStyles from "../../../../components/styles/styles";
import { postRequest } from "../../../../constant/ApiService";
import Api from "../../../../constant/Api";
import { toast } from "react-toastify";
import { getRequest } from "../../../../constant/ApiService";
import { MainContext } from "../../../../context/MainContext";

const NewAddressModal = ({ open, setOpen, id, address2, status }) => {
  const classes = useStyles();
  let {
    detail,
    setDetail,
    detailDisAddress,
    setDetailDisAddress,
    detailOriginAddress,
    setDetailOriginAddress,
  } = useContext(MainContext);

  const [newAddressData, setNewAddressData] = useState({
    address: "",
    priority: 0,
    postalCode: "",
    tellNumber: "",
  });
  const { address, priority, postalCode, tellNumber } = newAddressData;
  const handleCreateAddress = useCallback(() => {
    if (!address) {
      toast.error("آدرس را وارد کنید");
      return;
    }
    if (!priority) {
      toast.error("اولویت را وارد کنید");
      return;
    }
    if (postalCode && !/^[0-9]{10}$/.test(postalCode)) {
      toast.error("کدپستی را درست وارد کنید");
      return;
    }
    if (tellNumber && !/^[0-9]{11}$/.test(tellNumber)) {
      toast.error("شماره تلفن را درست وارد کنید");
      return;
    }

    let body = {
      barId: Number(id),
      type: status,
      address: address,
      lat: 0,
      lon: 0,
      priority: priority,
      postalCode: postalCode,
      phoneNumber: tellNumber,
      
    };
    postRequest(Api.CreateCargoAddress, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        getRequest(Api.GetCargoDetail + "?Id=" + id).then(res => {
          setDetail(res && res.data);
        });
        setOpen(false);
      }
    });
    setDetailDisAddress(detail.cargoDischargeLocations);
    setDetailOriginAddress(detail.cargoLoadingLocations);
  }, [detail, newAddressData]);

  return (
    <Modal open={open}>
      <Grid container className={classes.newAddressModal}>
        <h3>
          ثبت آدرس
          <Divider style={{ width: "100%", margin: "10px 0 0" }} />
        </h3>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            style={{ display: "flex", flexDirection: "row" }}
            item
            xl={12}
            className={classes.firstFiled}
          >
            <h4> {`مبداء : ${address2}`}</h4>
          </Grid>
          <Grid className={classes.colItem} item sm={12} xs={12}>
            <label>آدرس</label>
            <TextareaAutosize
              style={{ border: "solid 1px #dcdcdc", height: 78 }}
              value={address}
              onChange={({ target }) =>
                setNewAddressData(state => ({
                  ...state,
                  address: target.value,
                }))
              }
              rows={4}
              cols={4}
            />
          </Grid>
          <Grid className={classes.colItem} item sm={12} xs={12}>
            <label>الویت</label>
            <Input
              onChange={({ target }) =>
                setNewAddressData(state => ({
                  ...state,
                  priority: target.value,
                }))
              }
              value={priority}
              type="number"
            />
          </Grid>
          <Grid className={classes.colItem} item sm={12} xs={12}>
            <label>کد پستی</label>
            <Input
              onChange={({ target }) =>
                setNewAddressData(state => ({
                  ...state,
                  postalCode: target.value,
                }))
              }
              value={postalCode}
              type="number"
            />
          </Grid>
          <Grid className={classes.colItem} item sm={12} xs={12}>
            <label>شماره تلفن</label>
            <Input
              onChange={({ target }) =>
                setNewAddressData(state => ({
                  ...state,
                  tellNumber: target.value,
                }))
              }
              value={tellNumber}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          style={{ paddingTop: "25px" }}
        >
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            style={{
              backgroundColor: "inherit",
              color: "inherit",
              width: 110,
              marginLeft: 10,
            }}
          >
            انصراف
          </Button>
          <Button
            onClick={handleCreateAddress}
            variant="contained"
            style={{ backgroundColor: "#3CD4A0", color: "#fff", width: 110 }}
          >
            تایید
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NewAddressModal;
