import React, { useEffect, useState, useContext, useCallback } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button, Divider, Grid } from "@material-ui/core";

import useStyles from "../../../components/styles/styles";
import { MainContext } from "../../../context/MainContext";
import { useParams } from "react-router-dom";
import Api from "../../../constant/Api";
import { useHistory } from "react-router-dom";
import { putRequest } from "../../../constant/ApiService";
import { toast } from "react-toastify";
import Input from "../../../components/utils/Input";

const DetailFirmCustomer = props => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  let customerData = props.location.state;

  const [customerDatas, setCustomerDatas] = useState({
    companyName: "",
    companyphone: "",
    companyAreaCode: "",
    companyNationalCode: "",
    phone: "",
  });

  useEffect(() => {
    if (customerData) {
      setCustomerDatas({
        companyName: customerData.company_Name,
        companyphone: customerData.company_Tell,
        companyAreaCode: customerData.company_AreaCode_Tell,
        companyNationalCode: customerData.company_Nationl_Id,
        phone: customerData.phone,
      });
    }
  }, [customerData]);

  const confirmCustomerFunc = useCallback(() => {
    if (!customerDatas.companyName) {
      toast.error("نام شرکت را وارد کنید");
      return;
    }
    if (
      customerDatas.companyphone &&
      !/^[0-9]{8}$/.test(customerDatas.companyphone)
    ) {
      toast.error("شماره شرکت را درست وارد کنید");
      return;
    }
    if (
      customerDatas.companyAreaCode &&
      !/^[0-9]{3}$/.test(customerDatas.companyAreaCode)
    ) {
      toast.error("پیش شماره را درست وارد کنید");
      return;
    }
    if (
      customerDatas.companyNationalCode &&
      !/^[0-9]{10}$/.test(customerDatas.companyNationalCode)
    ) {
      toast.error("شناسه ملی شرکت را درست وارد کنید");
      return;
    }
    if (!customerDatas.phone) {
      toast.error("شماره همراه را وارد کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(customerDatas.phone)) {
      toast.error("شماره همراه را درست وارد  کنید");
      return;
    }

    let body = {
      id: id,
      company_Name: customerDatas.companyName,
      company_Tell: customerDatas.companyphone,
      company_AreaCode_Tell: customerDatas.companyAreaCode,
      company_Nationl_Id: customerData.companyNationalCode,
      stauts: 0,
      phone: customerDatas.phone,
      work_Status: 0,
    };
    putRequest(`${Api.UpdateLegalCustomer}/${id}`, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/FirmCustomer");
      }
    });
  }, [customerDatas]);
  return (
    <Grid container>
      <PageTitle title="جزییات مشتری " />
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>نام شرکت</label>
        <Input
          name="companyName"
          value={customerDatas.companyName}
          onChange={({ target }) =>
            setCustomerDatas(state => ({ ...state, companyName: target.value }))
          }
          type="text"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>شماره تلفن شرکت</label>
        <Input
          name="companyphone"
          value={customerDatas.companyphone}
          onChange={({ target }) =>
            setCustomerDatas(state => ({
              ...state,
              companyphone: target.value,
            }))
          }
          type="number"
          variant="outline"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>پیش شماره شرکت</label>
        <Input
          name="companyNationalCode"
          value={customerDatas.companyAreaCode}
          onChange={({ target }) =>
            setCustomerDatas(state => ({
              ...state,
              companyAreaCode: target.value,
            }))
          }
          type="number"
          variant="outline"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>شماره همراه</label>
        <Input
          name="companyNationalCode"
          value={customerDatas.phone}
          onChange={({ target }) =>
            setCustomerDatas(state => ({
              ...state,
              phone: target.value,
            }))
          }
          type="number"
          variant="outline"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>شناسه ملی شرکت</label>
        <Input
          name="companyNationalCode"
          value={customerDatas.companyNationalCode}
          onChange={({ target }) =>
            setCustomerDatas(state => ({
              ...state,
              companyNationalCode: target.value,
            }))
          }
          type="number"
          variant="outline"
        />
      </Grid>
      <Grid container justifyContent="space-between" direction="row-reverse">
        <Grid style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={confirmCustomerFunc}
          >
            ثبت{" "}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DetailFirmCustomer;
