import React, { useState, useContext, useEffect } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";
import { Grid, TextareaAutosize, Button, Divider } from "@material-ui/core";
import { STEPS } from "../../../constant/staticData";
import Select from "react-select";
import useStyles from "../../../components/styles/styles";
import { useHistory } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import DatePicker from "react-modern-calendar-datepicker";
import Input from "../../../components/utils/Input";
import TextField from "@material-ui/core/TextField";
import { MainContext } from "../../../context/MainContext";
import StepButton from "@material-ui/core/StepButton";
import { postRequest, getRequest } from "../../../constant/ApiService";
import Api from "../../../constant/Api";
import { toast } from "react-toastify";

const PreviewInfo = () => {
  const classes = useStyles();
  const history = useHistory();

  let {
    newOrder,
    setNewOrder,
    carrier,
    setCarrier,
    activeStep,
    setActiveStep,
    cargoDate,
    // handleStep,
  } = useContext(MainContext);

  const registerNewOrder = () => {
    let newHour =
      newOrder.hour.length !== 2 ? `0${newOrder.hour}` : `${newOrder.hour}`;
    let min =
      newOrder.minute.length !== 2
        ? `0${newOrder.minute}`
        : `${newOrder.minute}`;

    let body = {
      id: 0,
      sourceCityId: newOrder.origin.value,
      destinationCityId: newOrder.distination.value,
      packingId: carrier.packageTypesValue.value,
      vehicleClassId: carrier.truckTypeValue.value,
      loadingDate: `${cargoDate.year}/${cargoDate.month}/${cargoDate.day}`,
      loadingTime: `${newHour}:${min}`,
      title: newOrder.cargoName,
      weight: carrier.WeightValue.value,
      vehicleCount: Number(newOrder.cargoCount),
      description: newOrder.description,
      loadingTypes: [
        newOrder.loadingTypeNight
          ? {
              loadingTypeId: 1,
            }
          : "",
        newOrder.loadingTypeTeraffic
          ? {
              loadingTypeId: 4,
            }
          : "",
      ],
      loadingLocations: [
        {
          rowIndex: 0,
          address: newOrder.originAddress,
          lat: 0,
          long: 0,
          srtid: newOrder.origin.value,
          postalCode: newOrder.postalCodeOrigin,
        },
      ],
      dischargeLocations: [
        {
          rowIndex: 0,
          address: newOrder.distinationAddress,
          lat: 0,
          long: 0,
          srtid: newOrder.distination.value,
          postalCode: newOrder.postalCodeDis,
        },
      ],
      velicleFeatureId: carrier.carrierTypeValue.value,
      insuranceAmount: Number(newOrder.insuranceValue),
      requestPrice: 0,
      billOfLadingType: carrier.billOfLoadingValue.value,
      length: Number(newOrder.cargoLength),
      width: Number(newOrder.cargoWidth),
      height: Number(newOrder.cargoHeight),
      number: Number(newOrder.cargoCount),
      volume: 0,
      transfereeName: newOrder.transfereeName,
      transfereePhoneNumber: newOrder.transfereePhoneNumber,
      senderNamer: newOrder.name,
      senderPhoneNumber: newOrder.phoneNumber
    };
    console.log("bodyNewOrder",body)

    postRequest(Api.CreateCargo, body).then(res => {
      console.log("resNewOrder",res)
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/transactionsWaiting");
      }
    });
  };
  useEffect(() => {
    setActiveStep(5);
  }, []);

  window.onload = e => {
    if (performance.navigation.type == 1) {
      history.push("/app/newOrder");
    }
  };
  return (
    <Grid>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map((label, index) => (
          <Step key={label}>
            <StepButton>{label}</StepButton>{" "}
          </Step>
        ))}
      </Stepper>
      <PageTitle title="پیش نمایش اطلاعات" />

      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label> عنوان محموله</label>
          <TextField
            id="standard-read-only-input"
            defaultValue={newOrder.cargoName && newOrder.cargoName}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>تعداد محموله</label>
          <TextField
            id="standard-read-only-input"
            defaultValue={newOrder.cargoCount && newOrder.cargoCount}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>مکان بارگیری</label>
          <TextField
            id="standard-read-only-input"
            defaultValue={newOrder.origin && newOrder.origin.label}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>مکان تخلیه</label>
          <TextField
            id="standard-read-only-input"
            defaultValue={newOrder.distination && newOrder.distination.label}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نوع بسته بندی </label>
          <TextField
            id="standard-read-only-input"
            defaultValue={
              carrier.packageTypesValue && carrier.packageTypesValue.label
            }
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نوع بارگیر</label>
          <TextField
            id="standard-read-only-input"
            defaultValue={
              carrier.truckTypeValue && carrier.truckTypeValue.label
            }
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>وزن محموله </label>
          <TextField
            id="standard-read-only-input"
            defaultValue={carrier.WeightValue && carrier.WeightValue.label}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>بیمه</label>
          <TextField
            id="standard-read-only-input"
            defaultValue={
              newOrder.insuranceValue && newOrder.insuranceValue
                ? "دارد"
                : "ندارد"
            }
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>ارزش محموله</label>
          <TextField
            id="standard-read-only-input"
            defaultValue={newOrder.insuranceValue && newOrder.insuranceValue}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>تعداد محموله</label>
          <TextField
            id="standard-read-only-input"
            defaultValue={newOrder.cargoCount && newOrder.cargoCount}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" direction="row-reverse">
        <Grid style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={registerNewOrder}
          >
            ثبت سفارش
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PreviewInfo;
