import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../../components/styles/styles";
import {
  Button,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      className={classes.root404}
      style={{ paddingTop: "50px", justifyContent: "center", height: "100%" }}
    >
      <Typography
        className={classes.root404}
        variant="h3"
        component="div"
        gutterBottom
      >
        !صفحه موردنظر پیدا نشد
      </Typography>
      <Button
        variant="contained"
        style={{ color: "#fff", backgroundColor: "#2980B9" }}
        onClick={history.push("/")}
      >
        بازگشت به صفحه اصلی
      </Button>
    </Grid>
  );
};

export default NotFound;
