import React, { useState, useEffect, useContext } from "react";
import { Grid, TextareaAutosize, Divider, Button } from "@material-ui/core";
import Input from "../../components/utils/Input";
import useStyles from "../../components/styles/styles";
import { MainContext } from "../../context/MainContext";
import Select from "react-select";
import { DEGREEDRIVER } from "../../constant/staticData";
import { postRequest, getRequest } from "../../constant/ApiService";
import Api from "../../constant/Api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import PageTitle from "../../components/PageTitle/PageTitle";

const DetailDriver = props => {
  const classes = useStyles();
  const history = useHistory();
  let driverData = props.location.state;
  const [birthDate, setBirthDate] = useState(null);
  const [expireInsuranceSales, setExpireInsuranceSales] = useState(null);
  const [expireInsuranceBadane, setExpireInsuranceBadane] = useState(null);
  let { city, setCity, cityOption,setCityPos, cityPos,} = useContext(MainContext);

  const [degreeDriver, setDegreeDriver] = useState({});
  const [driverChar, setDriverChar] = useState({
    name: "",
    family: "",
    phone: "",
    identityNumber: "",
    cardNumber: "",
    shabaCardNumber: "",
    degreeDriverValue: "",
    cityValue: "",

  });

    const cityOption3 = item => {
    getRequest( `${Api.GetCity}?CityName=${item}`).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.cityName,
          };
        });

      setCity(handleOptions);
      setCityPos(res && res.data.lists);
    });
  };

  const [carChar, setCarChar] = useState({
    tagNumber: "",
    yearOfConstraction: "",
    minWeight: 0,
    maxWeight: 0,
    brandName: "",
    phone: "",
  });
  let {
    carrier,
    setCarrier,
    handleCarrierType,
    handleTruckType,
    handleCargoLoadingTypes,
  } = useContext(MainContext);

  const degreeOption = () => {
    let handleOptions = DEGREEDRIVER.map(item => {
      return {
        value: item.value,
        label: item.label,
      };
    });
    setDegreeDriver(handleOptions);
  };

  useEffect(() => {
    handleCarrierType();
    handleTruckType();
    cityOption();
    degreeOption();
  }, []);

  useEffect(() => {
    if (driverData && driverData.expireNumInsuranceBadane != null) {
      let BadaneYear = parseInt(
        driverData.expireNumInsuranceBadane.substring(0, 4),
      );
      let BadaneMonth = driverData.expireNumInsuranceBadane.substring(5, 7);
      let BadaneDay = driverData.expireNumInsuranceBadane.substring(8, 10);

      setExpireInsuranceBadane({
        day: BadaneDay,
        month: BadaneMonth,
        year: BadaneYear,
      });
    }
    if (driverData && driverData.expireNumInsuranceSales != null) {
      let SalesYear = parseInt(
        driverData.expireNumInsuranceSales.substring(0, 4),
      );
      let SalesMonth = driverData.expireNumInsuranceSales.substring(5, 7);
      let SalesDay = driverData.expireNumInsuranceSales.substring(8, 10);
      setExpireInsuranceSales({
        year: SalesYear,
        month: SalesMonth,
        day: SalesDay,
      });
    }

    if (driverData && driverData.birthDayDate != null) {
      let birthDayYear = parseInt(driverData.birthDayDate.substring(0, 4));
      let birthDayMonth = driverData.birthDayDate.substring(5, 7);
      let birthDayDay = driverData.birthDayDate.substring(8, 10);
      setBirthDate({
        day: birthDayDay,
        month: birthDayMonth,
        year: birthDayYear,
      });
    }
  }, [driverData]);

  useEffect(() => {

    console.log("driverData" , driverData)
    if (driverData && degreeDriver) {
      setDriverChar({
        ...driverChar,
        cityValue: city.filter(x => x.value == driverData.birthDayCityId)[0],
        name: driverData.firstName,
        family: driverData.lastName,
        phone: driverData.phone,
        identityNumber: driverData.num_Card_National,
        cardNumber: driverData.num_Smart_Card,
        smartCardNumber: driverData.num_Smart_Card,
        degreeDriverValue:
          degreeDriver.length > 0 &&
          degreeDriver.filter(x => x.value == driverData.degreeId)[0],
      });
      setCarChar({
        tagNumber: driverData.navyNumber,
        yearOfConstraction: driverData.yearOfManufactureNavy,
        minWeight: driverData.min_Weight,
        maxWeight: driverData.max_Weight,
        brandName: driverData.brandName,
        phone: driverData.phone,
      });

      setCarrier({
        ...carrier,
        carrierTypeValue:
          carrier.carrierType.length > 0 &&
          carrier.carrierType.filter(x => x.value == driverData.carrierTypeeId),
        truckTypeValue:
          carrier.truckType.length > 0 &&
          carrier.truckType.filter(x => x.value == driverData.truckTypeId),
      });
    } else if (driverData == undefined) {
      setCarrier({
        ...carrier,
        carrierTypeValue: "",
        truckTypeValue: "",
      });
      setCarChar({
        ...carChar,
        tagNumber: "",
        yearOfConstraction: "",
        minWeight: "",
        maxWeight: "",
        brandName: "",
        phone: "",
      });
      setDriverChar({
        ...driverChar,
        name: "",
        family: "",
        phone: "",
        identityNumber: "",
        cardNumber: "",
        degreeDriver: "",
        degreeDriverValue: "",
        cityValue: "",
      });
      setExpireInsuranceSales("");
      setExpireInsuranceBadane("");
      setBirthDate("");
    }
  }, [driverData, city, degreeDriver]);

  const addNewCharDriver = () => {
    if (!driverChar.name) {
      toast.error("نام را وارد کنید");
      return;
    }
    if (!driverChar.family) {
      toast.error("نام خانوادگی را وارد کنید");
      return;
    }
    if (!driverChar.phone) {
      toast.error("شماره همراه را وارد کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(driverChar.phone)) {
      toast.error("شماره همراه را درست وارد  کنید");
      return;
    }
    if (!driverChar.smartCardNumber) {
      toast.error("شماره هوشمند  را وارد کنید");
      return;
    }
    if (
      driverChar.identityNumber &&
      !/^[0-9]{10}$/.test(driverChar.identityNumber)
    ) {
      toast.error("شماره ملی را درست وارد کنید");
      return;
    }
    if (driverChar.cardNumber && !/^[0-9]{16}$/.test(driverChar.cardNumber)) {
      toast.error("شماره کارت را درست وارد کنید");
      return;
    }
    if (
      driverChar.shabaCardNumber &&
      !/^[0-9]{26}$/.test(driverChar.shabaCardNumber)
    ) {
      toast.error("شماره شبا را درست وارد کنید");
      return;
    }
    let body = {
      firstName: driverChar.name,
      lastName: driverChar.family,
      cityId: driverChar.cityValue && driverChar.cityValue.value,
      birthDay:
        birthDate && `${birthDate.year}/${birthDate.month}/${birthDate.day}`,
      degreeId:
        driverChar.degreeDriverValue && driverChar.degreeDriverValue.value,
      degreeName:
        driverChar.degreeDriverValue && driverChar.degreeDriverValue.label,
      smartCardNumber: driverChar.smartCardNumber,
      cardNumber: driverChar.cardNumber,
      identityNumber: driverChar.identityNumber,
      phone: driverChar.phone,
    };

    postRequest(Api.CreateDriver, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/driversList");
      }
    });
  };

  const addNewCarrierDriver = () => {
    if (!carrier.carrierTypeValue) {
      toast.error("بارگیر را انتخاب کنید");
      return;
    }
    if (!carrier.truckTypeValue) {
      toast.error("نوع بارگیر را انتخاب کنید");
      return;
    }
    if (!carrier.carrierTypeValue) {
      toast.error("نوع بارگیر را انتخاب کنید");
      return;
    }
    if (!carChar.brandName) {
      toast.error("نوع برند را وارد کنید");
      return;
    }
 
    // if (!/^[0-9]{8}$/.test(carChar.tagNumber)) {
    //   toast.error("شماره پلاک را درست وارد  کنید");
    //   return;
    // }
    if (!carChar.yearOfConstraction) {
      toast.error("سال ساخت را انتخاب کنید");
      return;
    }
    if (!/^[0-9]{4}$/.test(carChar.yearOfConstraction)) {
      toast.error("سال ساخت را درست وارد  کنید");
      return;
    }
    if (!expireInsuranceSales) {
      toast.error("تاریخ بیمه شخص ثالث  را انتخاب کنید");
      return;
    }
    if (!expireInsuranceBadane) {
      toast.error("تاریخ بیمه بدنه  را انتخاب کنید");
      return;
    }
    if (!carChar.phone) {
      toast.error("شماره همراه  را انتخاب کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(carChar.phone)) {
      toast.error("شماره همراه را درست وارد  کنید");
      return;
    }
    if (!carChar.minWeight) {
      toast.error("حداقل وزن را وارد کنید");
      return;
    }
    if (!carChar.maxWeight) {
      toast.error("حداکثر وزن راوارد کنید");
      return;
    }
    let body = {
      expireNumInsuranceSales:
        expireInsuranceSales &&
        `${expireInsuranceSales.year}/${expireInsuranceSales.month}/${expireInsuranceSales.day}`,
      expireNumInsuranceBadane:
        expireInsuranceBadane &&
        `${expireInsuranceBadane.year}/${expireInsuranceBadane.month}/${expireInsuranceBadane.day}`,
      truckId: carrier.truckTypeValue && carrier.truckTypeValue.value,
      bargirId: carrier.carrierTypeValue && carrier.carrierTypeValue.value,
      navyNumber: carChar.tagNumber,
      navySystemType: "",
      brandName: carChar.brandName,
      yearOfManufactureNavy: carChar.yearOfConstraction.toString(),
      min_Weight: parseInt(carChar.minWeight),
      max_Weight: parseInt(carChar.maxWeight),
      phone: carChar.phone,
    };
    postRequest(Api.CreateDriverByNavy, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/driversList");
      }
    });
  };

  ////////////////////////////////////////////////////

  const addNewCarrierDriverAndDriver = () => {
    if (!driverChar.name) {
      toast.error("نام را وارد کنید");
      return;
    }
    if (!driverChar.family) {
      toast.error("نام خانوادگی را وارد کنید");
      return;
    }
    if (!driverChar.phone) {
      toast.error("شماره همراه را وارد کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(driverChar.phone)) {
      toast.error("شماره همراه را درست وارد  کنید");
      return;
    }
    if (!driverChar.smartCardNumber) {
      toast.error("شماره هوشمند  را وارد کنید");
      return;
    }
    if (
      driverChar.identityNumber &&
      !/^[0-9]{10}$/.test(driverChar.identityNumber)
    ) {
      toast.error("شماره ملی را درست وارد کنید");
      return;
    }
    // if (driverChar.cardNumber && !/^[0-9]{16}$/.test(driverChar.cardNumber)) {
    //   toast.error("شماره کارت را درست وارد کنید");
    //   return;
    // }
    // if (
    //   driverChar.shabaCardNumber &&
    //   !/^[0-9]{26}$/.test(driverChar.shabaCardNumber)
    // ) {
    //   toast.error("شماره شبا را درست وارد کنید");
    //   return;
    // }
    if (!carrier.truckTypeValue) {
      toast.error("بارگیر را انتخاب کنید");
      return;
    }

    if (!carrier.carrierTypeValue) {
      toast.error("نوع بارگیر را انتخاب کنید");
      return;
    }
    if (!carChar.brandName) {
      toast.error("نوع برند را وارد کنید");
      return;
    }
 
    // if (!/^[0-9]{8}$/.test(carChar.tagNumber)) {
    //   toast.error("شماره پلاک را درست وارد  کنید");
    //   return;
    // }
    if (!carChar.yearOfConstraction) {
      toast.error("سال ساخت را انتخاب کنید");
      return;
    }
    if (!/^[0-9]{4}$/.test(carChar.yearOfConstraction)) {
      toast.error("سال ساخت را درست وارد  کنید");
      return;
    }
    if (!expireInsuranceSales) {
      toast.error("تاریخ بیمه شخص ثالث  را انتخاب کنید");
      return;
    }
    if (!expireInsuranceBadane) {
      toast.error("تاریخ بیمه بدنه  را انتخاب کنید");
      return;
    }
    if (!carChar.phone) {
      toast.error("شماره همراه  را انتخاب کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(carChar.phone)) {
      toast.error("شماره همراه را درست وارد  کنید");
      return;
    }
    if (!carChar.minWeight) {
      toast.error("حداقل وزن را وارد کنید");
      return;
    }
    if (!carChar.maxWeight) {
      toast.error("حداکثر وزن راوارد کنید");
      return;
    }

    console.log("carrier" , carrier)
    var truckTypeValue = carrier.truckTypeValue[0] && carrier.truckTypeValue[0].value
    if (truckTypeValue == undefined){
      truckTypeValue = carrier.truckTypeValue && carrier.truckTypeValue.value
    }

    var carrierTypeValue = carrier.carrierTypeValue[0] && carrier.carrierTypeValue[0].value
    if (carrierTypeValue == undefined){
      carrierTypeValue = carrier.carrierTypeValue && carrier.carrierTypeValue.value
    }
    let body = {
      expireNumInsuranceSales:
        expireInsuranceSales &&
        `${expireInsuranceSales.year}/${expireInsuranceSales.month}/${expireInsuranceSales.day}`,
      expireNumInsuranceBadane:
        expireInsuranceBadane &&
        `${expireInsuranceBadane.year}/${expireInsuranceBadane.month}/${expireInsuranceBadane.day}`,
      truckId: truckTypeValue,
      bargirId: carrierTypeValue,
      navyNumber: carChar.tagNumber,
      navySystemType: "",
      brandName: carChar.brandName,
      yearOfManufactureNavy: carChar.yearOfConstraction.toString(),
      min_Weight: parseInt(carChar.minWeight),
      max_Weight: parseInt(carChar.maxWeight),
      firstName: driverChar.name,
      lastName: driverChar.family,
      cityId: driverChar.cityValue && driverChar.cityValue.value,
      birthDay:
        birthDate && `${birthDate.year}/${birthDate.month}/${birthDate.day}`,
      degreeId:
        driverChar.degreeDriverValue && driverChar.degreeDriverValue.value,
      degreeName:
        driverChar.degreeDriverValue && driverChar.degreeDriverValue.label,
      smartCardNumber: driverChar.smartCardNumber,
      cardNumber: driverChar.cardNumber,
      identityNumber: driverChar.identityNumber,
      phone: driverChar.phone,
    };

    console.log(body)
    postRequest(Api.CreateDriverAndNavy, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/driversList");
      }
    });
  };

  ///////////////////////////////////////////////////////

  return (
    <Grid container>
      {driverData == undefined ? (
        <PageTitle title="ثبت راننده" />
      ) : (
        <PageTitle title="جزییات راننده" />
      )}
      <Grid container>
        <h3>مشخصات راننده</h3>
      </Grid>
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نام</label>
          <Input
            onChange={({ target }) =>
              setDriverChar(state => ({
                ...state,
                name: target.value,
              }))
            }
            value={driverChar.name}
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نام خانوادگی</label>
          <Input
            onChange={({ target }) =>
              setDriverChar(state => ({
                ...state,
                family: target.value,
              }))
            }
            value={driverChar.family}
            type="text"
          />
        </Grid>
        <Grid
          className={`${classes.colItem} ${classes.datePicker}`}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>تاریخ تولد</label>
          <DatePicker
            value={birthDate}
            onChange={setBirthDate}
            shouldHighlightWeekends
            locale="fa"
            add
            this
            style={{ zIndex: "1" }}
          />
        </Grid>

        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>شماره ملی</label>
          <Input
            onChange={({ target }) =>
              setDriverChar(state => ({
                ...state,
                identityNumber: target.value,
              }))
            }
            value={driverChar.identityNumber}
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>تلفن</label>
          <Input
            onChange={({ target }) =>
              setDriverChar(state => ({
                ...state,
                phone: target.value,
              }))
            }
            value={driverChar.phone}
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>شماره هوشمند</label>
          <Input
            onChange={({ target }) =>
              setDriverChar(state => ({
                ...state,
                smartCardNumber: target.value,
              }))
            }
            value={driverChar.smartCardNumber}
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>شهر</label>
          <Select
            //onInputChange={cityOption3}
            onChange={e => {
              setDriverChar(  {           
                  ...driverChar,
                  cityValue: e,
              
              });
            }}
            options={city}
            value={driverChar.cityValue}
            placeholder="شهر را انتخاب کنید"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>مدرک تحصیلی</label>
          <Select
            onChange={e => {
              setDriverChar(prevDegree => {
                return {
                  ...prevDegree,
                  degreeDriverValue: e,
                };
              });
            }}
            options={degreeDriver}
            value={driverChar.degreeDriverValue}
            placeholder="مدرک تحصیلی را انتخاب کنید"
          />
        </Grid>

        
        {/* <Grid container>
          <h3>مشخصات کارت بانکی</h3>
          <Grid container>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>شماره کارت بانکی</label>
              <Input
                onChange={({ target }) =>
                  setDriverChar(state => ({
                    ...state,
                    cardNumber: target.value,
                  }))
                }
                value={driverChar.cardNumber}
                type="text"
              />
            </Grid>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>شماره شبا بانکی</label>
              <Input
                onChange={({ target }) =>
                  setDriverChar(state => ({
                    ...state,
                    shabaCardNumber: target.value,
                  }))
                }
                value={driverChar.shabaCardNumber}
                type="text"
              />
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent="space-between"
          direction="row-reverse"
        >
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={addNewCharDriver}
          >
            ثبت
          </Button>
        </Grid> */}
      </Grid>
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      <h3>مشخصات وسیله نقلیه</h3>
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نوع بارگیر</label>
          <Select
            onChange={e => setCarrier({ ...carrier, carrierTypeValue: e })}
            options={carrier.carrierType}
            value={carrier.carrierTypeValue}
            placeholder="نوع بارگیر را انتخاب کنید"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>بارگیر</label>
          <Select
            onChange={e => {
              console.log('eeee',e)
              setCarrier({ ...carrier, truckTypeValue: e })}}
            options={carrier.truckType}
            value={carrier.truckTypeValue}
            placeholder="بارگیر را انتخاب کنید"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نوع برند</label>
          <Input
            onChange={({ target }) =>
              setCarChar(state => ({
                ...state,
                brandName: target.value,
              }))
            }
            value={carChar.brandName}
            type="text"
          />
        </Grid>
        <Grid
          className={`${classes.colItem} ${classes.datePicker}`}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label> تاریخ انقضا بیمه شخص ثالت</label>
          <DatePicker
            value={expireInsuranceSales}
            onChange={setExpireInsuranceSales}
            shouldHighlightWeekends
            locale="fa"
            add
            this
            style={{ zIndex: "1" }}
          />
        </Grid>
        <Grid
          className={`${classes.colItem} ${classes.datePicker}`}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>تاریخ انقضا بیمه بدنه</label>
          <DatePicker
            onChange={setExpireInsuranceBadane}
            value={expireInsuranceBadane}
            shouldHighlightWeekends
            locale="fa"
            add
            this
            style={{ zIndex: "1" }}
          />
        </Grid>
        {/* <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>شماره پلاک کامیون</label>
          <Input
            onChange={({ target }) =>
              setCarChar(state => ({
                ...state,
                tagNumber: target.value,
              }))
            }
            value={carChar.tagNumber}
            type="text"
          />
        </Grid> */}
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>سال ساخت کامیون</label>
          <Input
            onChange={({ target }) =>
              setCarChar(state => ({
                ...state,
                yearOfConstraction: target.value,
              }))
            }
            value={carChar.yearOfConstraction}
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>حداقل وزن </label>
          <Input
            onChange={({ target }) =>
              setCarChar(state => ({
                ...state,
                minWeight: target.value,
              }))
            }
            value={carChar.minWeight}
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>حداکثر وزن</label>
          <Input
            onChange={({ target }) =>
              setCarChar(state => ({
                ...state,
                maxWeight: target.value,
              }))
            }
            value={carChar.maxWeight}
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>تلفن</label>
          <Input
            onChange={({ target }) =>
              setCarChar(state => ({
                ...state,
                phone: target.value,
              }))
            }
            value={carChar.phone}
            type="text"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent="space-between"
          direction="row-reverse"
        >
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={addNewCarrierDriverAndDriver}
          >
            ثبت
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailDriver;
