import React from "react";
import useStyles from "../styles/styles"

const Input = ({value, onChange, type}) => {
  const classes = useStyles()
  return (
    <>
      <input type={type} value={value} onChange={onChange} className={classes.formControl}/>
    </>
  );
};

export default Input;