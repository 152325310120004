import React, { useEffect, useState, useContext, useCallback } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Divider, Grid } from "@material-ui/core";
import useStyles from "../../components/styles/styles";
import { MainContext } from "../../context/MainContext";
import { useParams } from "react-router-dom";
import Api from "../../constant/Api";
import { useHistory } from "react-router-dom";
import { putRequest, getRequest } from "../../constant/ApiService";
import Input from "../../components/utils/Input";
import Select from "react-select";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { toast } from "react-toastify";

const DetailTransportationCompany = props => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  let transportationData = props.location.state;
  const [contractDate, setContractDate] = useState(null);
  let { city, setCity, cityOption } = useContext(MainContext);
  const [image, setimage] = useState("");
  const [transportaionCompanyDatas, setTransportaionCompanyDatas] = useState({
    name: "",
    cityValue: "",
    address: "",
    tellNumber: "",
    managerName: "",
    managerPhone: "",
    contractNumber: "",
    userName: "",
    password: "",
  });
  useEffect(() => {
    cityOption();
  }, []);
  useEffect(() => {
    if (transportationData) {
      setTransportaionCompanyDatas({
        ...transportaionCompanyDatas,
        name: transportationData.name,
        cityValue: city.filter(x => x.value == transportationData.cityId)[0],
        address: transportationData.address,
        tellNumber: transportationData.tellNumber,
        managerName: transportationData.managerName,
        managerPhone: transportationData.managerPhone,
        contractNumber: transportationData.contractNumber,
        // contractDate: transportationData.contractDate,
        userName: transportationData.userName,
        password: transportationData.password,
      });
    }
  }, [transportationData, city]);
  useEffect(() => {
    if (transportationData && transportationData.persianContractDate != null) {
      let contractDateYear = parseInt(
        transportationData.persianContractDate.substring(0, 4),
      );
      let contractDateMonth = transportationData.persianContractDate.substring(
        5,
        7,
      );
      let contractDateDay = transportationData.persianContractDate.substring(
        8,
        10,
      );
      setContractDate({
        day: contractDateDay,
        month: contractDateMonth,
        year: contractDateYear,
      });
    }
    if (transportationData) {
      setimage(transportationData.contractImage);
    }
  }, [transportationData]);

  const confirmCustomerFunc = useCallback(() => {
    if (!transportaionCompanyDatas.name) {
      toast.error("نام شرکت را وارد کنید");
      return;
    }
    if (!transportaionCompanyDatas.cityValue) {
      toast.error("شهر را وارد کنید");
      return;
    }
    if (!transportaionCompanyDatas.address) {
      toast.error("آدرس را وارد کنید");
      return;
    }
    if (!transportaionCompanyDatas.tellNumber) {
      toast.error("شماره تلفن را وارد کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(transportaionCompanyDatas.tellNumber)) {
      toast.error("شماره تلفن را درست وارد  کنید");
      return;
    }
    if (!transportaionCompanyDatas.managerName) {
      toast.error("نام مدیر را وارد کنید");
      return;
    }
    if (!transportaionCompanyDatas.managerPhone) {
      toast.error("شماره همراه مدیر را وارد کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(transportaionCompanyDatas.managerPhone)) {
      toast.error("شماره همراه مدیر را درست وارد  کنید");
      return;
    }
    if (!transportaionCompanyDatas.contractNumber) {
      toast.error("شماره قرارداد را وارد کنید");
      return;
    }
    if (!contractDate) {
      toast.error("تاریخ قرارداد را وارد کنید");
      return;
    }
    if (!transportaionCompanyDatas.userName) {
      toast.error("نام کاربری را وارد کنید");
      return;
    }
    if (!transportaionCompanyDatas.password) {
      toast.error("کلمه عبور را وارد کنید");
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(
        transportaionCompanyDatas.password,
      )
    ) {
      toast.error(
        "کلمه عبور باید شامل حروف بزرگ و کوچک ,عددوکاراکترهای خاص وحداقل ۶رقم باشد",
      );
      return;
    }

    let body = {
      id: id,
      name: transportaionCompanyDatas.name,
      cityId:
        transportaionCompanyDatas.cityValue &&
        transportaionCompanyDatas.cityValue.value,
      address: transportaionCompanyDatas.address,
      contractImage: image.toString(),
      tellNumber: transportaionCompanyDatas.tellNumber,
      managerName: transportaionCompanyDatas.managerName,
      managerPhone: transportaionCompanyDatas.managerPhone,
      contractNumber: transportaionCompanyDatas.contractNumber,
      contractDate: `${contractDate.year}/${contractDate.month}/${contractDate.day}`,
      wagePecent: 0,
      userName: transportaionCompanyDatas.userName,
      password: transportaionCompanyDatas.password,
    };
    putRequest(`${Api.UpdateTransportationCompany}/${id}`, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/transportationCompany");
      }
    });
  }, [transportaionCompanyDatas]);

  const uploadFile = async event => {
    const file = event.target.files[0];
    const base64 = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
    setimage(base64);
  };

  return (
    <Grid container>
      <PageTitle title="جزییات باربری " />
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>نام </label>
        <Input
          name="name"
          value={transportaionCompanyDatas.name}
          onChange={({ target }) =>
            setTransportaionCompanyDatas(state => ({
              ...state,
              name: target.value,
            }))
          }
          type="text"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>نام شهر</label>
        <Select
          onChange={e => {
            setTransportaionCompanyDatas(prevCity => {
              return {
                ...prevCity,
                cityValue: e,
              };
            });
          }}
          options={city}
          value={transportaionCompanyDatas.cityValue}
          placeholder="شهر را انتخاب کنید"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>آدرس</label>
        <Input
          name="address"
          value={transportaionCompanyDatas.address}
          onChange={({ target }) =>
            setTransportaionCompanyDatas(state => ({
              ...state,
              address: target.value,
            }))
          }
          variant="outline"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>شماره تلفن</label>
        <Input
          name="tellNumber"
          value={transportaionCompanyDatas.tellNumber}
          onChange={({ target }) =>
            setTransportaionCompanyDatas(state => ({
              ...state,
              tellNumber: target.value,
            }))
          }
          type="number"
          variant="outline"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>نام مدیر</label>
        <Input
          name="managerName"
          value={transportaionCompanyDatas.managerName}
          onChange={({ target }) =>
            setTransportaionCompanyDatas(state => ({
              ...state,
              managerName: target.value,
            }))
          }
          variant="outline"
        />
      </Grid>

      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>تلفن مدیر</label>
        <Input
          name="managerPhone"
          value={transportaionCompanyDatas.managerPhone}
          onChange={({ target }) =>
            setTransportaionCompanyDatas(state => ({
              ...state,
              managerPhone: target.value,
            }))
          }
          type="number"
          variant="outline"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>عکس </label>
        <Input
          name="image"
          // value={image}
          onChange={uploadFile}
          type="file"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>شماره قرارداد</label>
        <Input
          name="contractNumber"
          value={transportaionCompanyDatas.contractNumber}
          onChange={({ target }) =>
            setTransportaionCompanyDatas(state => ({
              ...state,
              contractNumber: target.value,
            }))
          }
          type="number"
          variant="outline"
        />
      </Grid>
      <Grid
        className={`${classes.colItem} ${classes.datePicker}`}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>تاریخ قرارداد</label>
        <DatePicker
          value={contractDate}
          onChange={setContractDate}
          shouldHighlightWeekends
          locale="fa"
          add
          this
          style={{ zIndex: "1" }}
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>نام کاربری</label>
        <Input
          name="userName"
          value={transportaionCompanyDatas.userName}
          onChange={({ target }) =>
            setTransportaionCompanyDatas(state => ({
              ...state,
              userName: target.value,
            }))
          }
          variant="outline"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>رمزعبور</label>
        <Input
          name="password"
          value={transportaionCompanyDatas.password}
          onChange={({ target }) =>
            setTransportaionCompanyDatas(state => ({
              ...state,
              password: target.value,
            }))
          }
          type="password"
          variant="outline"
        />
      </Grid>
      <Grid container justifyContent="space-between" direction="row-reverse">
        <Grid style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={confirmCustomerFunc}
            // disabled={disabled}
          >
            ثبت
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DetailTransportationCompany;
