import React, { useEffect, useState } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import CommentIcon from "@material-ui/icons/Comment";
import InfoIcon from "@material-ui/icons/Info";
import GavelIcon from "@material-ui/icons/Gavel";
import {
  ArrowBack as ArrowBackIcon,
  Audiotrack,
  BorderAll as TableIcon,
  FilterNone as UIElementsIcon,
  FormatSize as TypographyIcon,
  HelpOutline as FAQIcon,
  Home as HomeIcon,
  LibraryBooks as LibraryIcon,
  NotificationsNone as NotificationsIcon,
  QuestionAnswer as SupportIcon,
  Book,
  Movie as MovieIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// styles
import useStyles from "./styles";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";
// context
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";

const structure = [
  {
    id: 0,
    label: "داشبورد",
    link: "/app/dashboard",
    icon: <DashboardIcon />,
  },
  {
    id: 1,
    label: "ثبت سفارش",
    icon: <AddShoppingCartIcon />,
    children: [
      {
        label: "ثبت سفارش",
        link: "/app/newOrder",
      },
      {
        label: "سوشال مدیا",
        link: "/app/newSocialMediaOrder",
      },
      {
        label: " ثبت بار عمومی",
        link: "/app/socialMediaOrder",
      },
    ],
  },
  {
    id: 2,
    label: "بخش مشتریان",
    icon: <PeopleIcon />,
    children: [
      {
        label: "لیست مشتریان",
        icon: <AccountBoxIcon />,
        children: [
          { label: "حقیقی", link: "/app/individualCustomer" },
          { label: "حقوقی", link: "/app/firmCustomer" },
          { label: "شرکت حمل و نقل( متصدی )", link: "/app/operatorCustomer" },
        ],
      },
      {
        label: "ثبت مشتری",
        link: "/app/newCustomer",
      },
    ],
  },
  {
    id: 3,
    label: "بخش رانندگان",
    icon: <PeopleAltIcon />,
    children: [
      {
        label: "لیست رانندگان",
        link: "/app/driversList",
      },
      {
        label: "ثبت راننده",
        link: "/app/detailDriver",
      },
    ],
  },
  {
    id: 4,
    label: "حساب کاربری",
    icon: <PeopleAltIcon />,
    children: [
      {
        label: "کاربران",
        link: "/app/crmUser",
      },
      {
        label: " ثبت کاربران",
        link: "/app/newCrmUser",
      },
    ],
  },
  {
    id: 5,
    label: "سفارش",
    icon: <ShoppingCartIcon />,
    children: [
      {
        label: "متصدی",
        link: "/app/motesaddiCargos",
      },
      {
        label: "در انتظار",
        link: "/app/transactionsWaiting",
      },
      {
        label: "در حال انجام",
        link: "/app/transactions",
      },
      {
        label: "به اتمام رسیده",
        link: "/app/finishedTransaction",
      },
      {
        label: "رد شده",
        link: "/app/transactionsCancel",
      },
    ],
  },
  {
    id: 6,
    label: "تماس با ما",
    link: "/app/notifications",
    icon: <ContactPhoneIcon />,
  },
  {
    id: 7,
    label: "نظرات",
    icon: <CommentIcon />,
    children: [
      {
        label: "لیست نظرات",
        link: "/app/comments",
      },
      {
        label: "ثبت نظر",
        link: "/app/newComment",
      },
    ],
  },
  {
    id: 8,
    label: "درباره ما",
    link: "/app/aboutUs",
    icon: <InfoIcon />,
  },
  {
    id: 9,
    label: "قوانین",
    link: "/app/rule",
    icon: <GavelIcon />,
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
         [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          // [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={true}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
