import React, { useEffect, useState, useContext, useCallback } from "react";

import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import { MainContext } from "../../../context/MainContext";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Api from "../../../constant/Api";
import { useHistory } from "react-router-dom";
import { getRequest } from "../../../constant/ApiService";
import { useParams } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useStyles from "../../../components/styles/styles";
import Input from "../../../components/utils/Input";

const IndividualCustomer = () => {
  const [items, setItems] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  const [customerSearch, setCustomerSearch] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    code: "",
  });
  let {
    handleChangeRowsPerPage,
    // handleChangePage,
    openPopup,
    setOpenPopup,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    detailCustomer,
    setDetailCustomer,
  } = useContext(MainContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getCustomerList = () => {
    getRequest(
      `${Api.GetCargoOwnersNaturalList}?FirstName=${
        customerSearch.firstName
      }&LastName=${customerSearch.lastName}&PhoneNumber=${
        customerSearch.phone
      }&PageNumber=${page + 1}&PageSize=${rowsPerPage}`,
    ).then(res => {
      setItems(res && res.data);
    });
  };
  useEffect(() => {
    getCustomerList();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getCustomerList();
  };
  return (
    <Grid>
      <PageTitle title="لیست مشتریان حقیقی" />
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نام </label>
          <Input
            name="firstName"
            value={customerSearch.firstName}
            onChange={({ target }) =>
              setCustomerSearch(state => ({
                ...state,
                firstName: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نام خانوادگی </label>
          <Input
            name="lastName"
            value={customerSearch.lastName}
            onChange={({ target }) =>
              setCustomerSearch(state => ({ ...state, lastName: target.value }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>شماره تلفن مشتری </label>
          <Input
            name="phone"
            value={customerSearch.phone}
            onChange={({ target }) =>
              setCustomerSearch(state => ({ ...state, phone: target.value }))
            }
            type="tel"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>کد مشترک </label>
          <Input
            name="code"
            value={customerSearch.code}
            onChange={({ target }) =>
              setCustomerSearch(state => ({ ...state, code: target.value }))
            }
            type="number"
          />
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          direction="row-reverse"
          style={{ padding: "20px 0" }}
        >
          <Grid style={{ display: "flex" }}>
            <Button
              variant="contained"
              style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
              onClick={getCustomerList}
            >
              جستجو کنید
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>ردیف</TableCell>
              <TableCell>شناسه</TableCell>
              <TableCell> نام</TableCell>
              <TableCell>نام خانوادگی</TableCell>
              <TableCell>کد ملی</TableCell>
              <TableCell>کد مشترک </TableCell>
              <TableCell>کد معرف دعوت کننده</TableCell>
              <TableCell>موبایل</TableCell>
              <TableCell>امتیاز</TableCell>
              <TableCell>تاریخ ثبت نام</TableCell>
              <TableCell>اقدام</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.lists &&
              items.lists.length > 0 &&
              items.lists.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.id}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.firstName}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.lastName}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.security_Number}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.reagent_Code}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.inviter_Code}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.phone}
                  </TableCell>
                  {/* <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.phone}
                  </TableCell> */}
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.rate}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailIndividualCustomer/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.created}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: "20ch",
                        },
                      }}
                    >
                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="primary"
                        >
                          مدیریت مالی
                        </Button>
                      </MenuItem>

                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="secondary"
                          // onClick={()=>deleteCargoFunc(item.id)}
                        >
                          قراردادی
                        </Button>
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {console.log(page)}

      <TablePagination
        labelRowsPerPage=""
        rowsPerPageOptions={[]}
        component="div"
        count={items && items.count > 0 ? items.count : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
};
export default IndividualCustomer;
