import React, { useState, useContext, useEffect } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";
import { Grid, TextareaAutosize, Button, Divider } from "@material-ui/core";
import { STEPS } from "../../../constant/staticData";
import Select from "react-select";
import useStyles from "../../../components/styles/styles";
import { useHistory } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import DatePicker from "react-modern-calendar-datepicker";
import Input from "../../../components/utils/Input";
import { MainContext } from "../../../context/MainContext";
import StepButton from "@material-ui/core/StepButton";
import { toast } from "react-toastify";

const DetailCargo = () => {
  const classes = useStyles();
  const history = useHistory();

  let {
    newOrder,
    setCarrier,
    setNewOrder,
    handleCargoWeight,
    carrier,
    handleCargoPackageTypes,
    activeStep,
    setActiveStep,
  } = useContext(MainContext);

  const confirmDetailCargo = () => {
    if (!newOrder.cargoName) {
      toast.error("عنوان بار را وارد کنید");
      return;
    }
    if (!carrier.WeightValue) {
      toast.error("وزن بار را وارد کنید");
      return;
    }
    if (!carrier.packageTypesValue) {
      toast.error("نوع بسته بندی  بار را وارد کنید");
      return;
    }
    if (!newOrder.insuranceValue) {
      toast.error("ارزش بیمه بار را وارد کنید");
      return;
    }
    history.push("/app/truckType");
  };

  useEffect(() => {
    handleCargoPackageTypes();
    handleCargoWeight();
  }, []);

  useEffect(() => {
    setActiveStep(2);
  }, []);

  window.onload = e => {
    if (performance.navigation.type == 1) {
      history.push("/app/newOrder");
    }
  };

  return (
    <Grid>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map((label, index) => (
          <Step key={label}>
            <StepButton
            // onClick={() => {
            //   handleStep(index);
            // }}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <PageTitle title="جزئیات محموله" />

      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>بار</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                cargoName: target.value,
              }))
            }
            value={newOrder.cargoName}
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>وزن محموله</label>
          <Select
            onChange={e => setCarrier({ ...carrier, WeightValue: e })}
            options={carrier.cargoWeight}
            value={carrier.WeightValue}
            placeholder="وزن محموله را انتخاب کنید"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نوع بسته بندی</label>
          <Select
            onChange={e => setCarrier({ ...carrier, packageTypesValue: e })}
            options={carrier.packageTypes}
            value={carrier.packageTypesValue}
            placeholder="نوع بسته بندی را انتخاب کنید"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>تعداد محموله</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                cargoCount: target.value,
              }))
            }
            value={newOrder.cargoCount}
            type="number"
          />
        </Grid>

        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>عرض محموله</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                cargoWidth: target.value,
              }))
            }
            value={newOrder.cargoWidth}
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>طول محموله</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                cargoLength: target.value,
              }))
            }
            value={newOrder.cargoLength}
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>ارتفاع محموله</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                cargoHeight: target.value,
              }))
            }
            value={newOrder.cargoHeight}
            type="number"
          />
        </Grid>

        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>ارزش بیمه (میلیون تومان)</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                insuranceValue: target.value,
              }))
            }
            value={newOrder.insuranceValue}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" direction="row-reverse">
        <Grid style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={confirmDetailCargo}
            //   disabled={disabled}
          >
            مرحله بعد
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DetailCargo;
