import React from "react";
import { Modal, Grid, Button, Divider } from "@material-ui/core";
import useStyles from "../styles/styles";

const CustomPopup = ({open, setOpen, text, bgColor1, bgColor2, btnTxt1, btnTxt2, title, click }) => {
  const classes = useStyles();
  return (
    <Modal open={open}>
      <Grid className={classes.modalPop}>
        <h3>
          {title}
          <Divider style={{width: "100%", margin: "10px 0 0"}} />
        </h3>
        <Grid style={{height: "100%"}}>
          {text}
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button onClick={click} variant="contained" style={{backgroundColor: bgColor1, color: btnTxt1, width: 110, marginLeft: 10}}>تایید</Button>
          <Button onClick={() => setOpen({ show: false, id: null})} variant="contained" style={{backgroundColor: bgColor2, color: btnTxt2, width: 110}}>انصراف</Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CustomPopup;