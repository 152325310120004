import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { getRequest } from "../constant/ApiService";
import Api from "../constant/Api";

const MainContext = createContext({});
export { MainContext };

export function MainContextProvider({ children }) {
  const [transactionData, setTransactionData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [weightValue, setWeightValue] = React.useState("");
  const [packageValue, setPackageValue] = React.useState("");
  const [detailOriginAddress, setDetailOriginAddress] = React.useState([]);
  const [detailDisAddress, setDetailDisAddress] = React.useState([]);

  const [detailAddress, setDetailAddress] = useState({
    name: "",
    transfereeName: "",
    mobile: "",
    transfereePhoneNumber: "",
    source: "",
    distination: "",
  });
  const [detailCargo, setDetailCargo] = useState({
    cargo: "",
    length: 0,
    height: 0,
    width: 0,
    number: 0,
    volume: 0,
    status: "",
    value: "",
    numCargo: "",
    desTopCargo: "",
    weight: "",
    insurance: "",
  });
  const [inProgressData, setInProgressData] = useState([]);
  const [finishedData, setFinishedData] = useState([]);
  const [transactionsDetailData, setTransactionsDetailData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [carrierType, setCarrierType] = useState([]);
  const [city, setCity] = useState([]);
  const [cityOption2, setcityOption2] = useState([]);

  const [carrier, setCarrier] = useState({
    carrierType: [],
    carrierTypeValue: "",
    truckType: [],
    truckTypeValue: "",
    cargoLoadingType: [],
    cargoLoadingTypeValue: "",
    packageTypes: "",
    packageTypesValue: "",
    weightValue: "",
    cargoWeight: "",
    billOfLoadingValue: "",
  });
  /////////customer
  const [detailCustomer, setDetailCustomer] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [cityPos, setCityPos] = useState([]);
  //////////NewOrder///////////////////////////
  const [cargoDate, setCargoDate] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const [disabled, setDisabled] = React.useState(false);
  const [newOrder, setNewOrder] = useState({
    hour: 0,
    minute: 0,
    originAddress: "",
    distinationAddress: "",
    cargoName: "",
    cargoWeight: "",
    cargoCount: "",
    cargoLength: 0,
    cargoWidth: 0,
    cargoHeight: 0,
    insuranceValue: 0,
    cargoLoadingType: "",
    cargoDischrgeType: "",
    origin: "",
    distination: "",
    loadingTypeNight: false,
    loadingTypeTeraffic: false,
    description: "",
    postalCodeOrigin: "",
    postalCodeDis: "",
    transfereeName: "",
    transfereePhoneNumber: "",
    name: "",
    phoneNumber: "",
  });

  /////////////////functions/////////////////////////////////////
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCarrierType = () => {
    getRequest(Api.GetCarrierTypes).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.title,
          };
        });

      setCarrier(prevCarrier => {
        return {
          ...prevCarrier,
          carrierType: handleOptions,
        };
      });
    });
  };
  const cityOption = () => {
    var item = 'ا';
    getRequest( `${Api.GetCity}?CityName=${item}`).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.cityName,
          };
        });

      setCity(handleOptions);
      setCityPos(res && res.data.lists);
    });
  };

  const handleTruckType = () => {
    getRequest(Api.GetTruckTypes).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.title,
          };
        });

      setCarrier(prevCarrier => {
        return {
          ...prevCarrier,
          truckType: handleOptions,
        };
      });
    });
  };

  const handleCargoLoadingTypes = () => {
    getRequest(Api.GetCargoLoadingTypes).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.title,
          };
        });
      setCarrier(prevCarrier => {
        return {
          ...prevCarrier,
          cargoLoadingType: handleOptions,
        };
      });
    });
  };

  const handleCargoPackageTypes = () => {
    getRequest(Api.GetPackageTypes).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.title,
          };
        });
      setCarrier(prevCarrier => {
        return {
          ...prevCarrier,
          packageTypes: handleOptions,
        };
      });
    });
  };

  const handleCargoWeight = () => {
    getRequest(Api.GetWeights).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.title,
          };
        });
      setCarrier(prevCarrier => {
        return {
          ...prevCarrier,
          cargoWeight: handleOptions,
        };
      });
    });
  };

  return (
    <MainContext.Provider
      value={{
        activeStep,
        setActiveStep,
        handleCargoWeight,
        handleChangeRowsPerPage,
        openPopup,
        setOpenPopup,
        rowsPerPage,
        setRowsPerPage,
        page,
        setPage,
        transactionsDetailData,
        setTransactionsDetailData,
        finishedData,
        setFinishedData,
        transactionData,
        setTransactionData,
        detail,
        setDetail,
        inProgressData,
        setInProgressData,
        detailCustomer,
        setDetailCustomer,
        customer,
        setCustomer,
        carrierType,
        setCarrierType,
        handleCarrierType,
        carrier,
        setCarrier,
        handleTruckType,
        handleCargoLoadingTypes,
        city,
        setCity,
        cityOption,
        cityPos,
        setCityPos,
        newOrder,
        setNewOrder,
        cargoDate,
        setCargoDate,
        handleCargoPackageTypes,
        disabled,
        setDisabled,
        detailAddress,
        setDetailAddress,
        detailCargo,
        setDetailCargo,
        weightValue,
        setWeightValue,
        packageValue,
        setPackageValue,
        detailDisAddress,
        setDetailDisAddress,
        detailOriginAddress,
        setDetailOriginAddress,
        setcityOption2
      }}
    >
      {children}
    </MainContext.Provider>
  );
}
