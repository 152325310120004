import React, { useState, useContext, useEffect } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";
import { Grid, TextareaAutosize, Button, Divider } from "@material-ui/core";
import { STEPS } from "../../../constant/staticData";
import Select from "react-select";
import useStyles from "../../../components/styles/styles";
import { useHistory } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import DatePicker from "react-modern-calendar-datepicker";
import Input from "../../../components/utils/Input";
import { MainContext } from "../../../context/MainContext";
import StepButton from "@material-ui/core/StepButton";
import { toast } from "react-toastify";

const CargoAddressInfo = () => {
  const classes = useStyles();
  const history = useHistory();

  let {
    newOrder,
    setNewOrder,
    cargoDate,
    setCargoDate,
    activeStep,
    setActiveStep,
  } = useContext(MainContext);

  const confirmAddressCargo = () => {
    if (!cargoDate) {
      toast.error("تاریخ را انتخاب کنید");
      return;
    }
    if (!newOrder.hour) {
      toast.error("ساعت راوارد کنید");
      return;
    }
    if (!newOrder.minute) {
      toast.error("دقیقه راوارد کنید");
      return;
    }
    if (!newOrder.name) {
      toast.error("نام فرستنده را وارد کنید");
      return;
    }
    if (!newOrder.phoneNumber) {
      toast.error("شماره تلفن مبداء را وارد کنید");
      return;
    }
    if (newOrder.phoneNumber && !/^[0-9]{11}$/.test(newOrder.phoneNumber)) {
      toast.error("شماره تلفن مبداء را درست وارد کنید");
      return;
    }
    if (!newOrder.originAddress) {
      toast.error("آدرس شهر مبدارا وارد کنید ");
      return;
    }
    if (!newOrder.postalCodeOrigin) {
      toast.error("کدپستی مبداء راوارد کنید");
      return;
    }
    if (
      newOrder.postalCodeOrigin &&
      !/^[0-9]{10}$/.test(newOrder.postalCodeOrigin)
    ) {
      toast.error("کد پستی مبداء را درست وارد کنید");
      return;
    }
    if (!newOrder.distinationAddress) {
      toast.error("آدرس شهر مقصدرا وارد کنید");
      return;
    }
    if (!newOrder.transfereeName) {
      toast.error("نام گیرنده را وارد کنید");
      return;
    }
    if (!newOrder.transfereePhoneNumber) {
      toast.error("شماره تلفن مقصد را وارد کنید");
      return;
    }
    if (
      newOrder.transfereePhoneNumber &&
      !/^[0-9]{11}$/.test(newOrder.transfereePhoneNumber)
    ) {
      toast.error("شماره تلفن مقصد را درست وارد کنید");
      return;
    }
    if (!newOrder.postalCodeDis) {
      toast.error("کدپستی مقصد راوارد کنید");
      return;
    }
    if (newOrder.postalCodeDis && !/^[0-9]{10}$/.test(newOrder.postalCodeDis)) {
      toast.error("کد پستی مقصد را درست وارد کنید");
      return;
    }
    history.push("/app/detailCargo");
  };

  useEffect(() => {
    setActiveStep(1);
  }, []);

  window.onload = e => {
    if (performance.navigation.type == 1) {
      history.push("/app/newOrder");
    }
  };

  return (
    <Grid>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map((label, index) => (
          <Step key={label}>
            <StepButton>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <PageTitle title="اطلاعات بارگیری" />

      <Grid container>
        <h3>تاریخ و ساعت بارگیری</h3>
        <Grid container>
          <Grid
            className={`${classes.colItem} ${classes.datePicker}`}
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <label>تاریخ بارگیری</label>
            <DatePicker
              value={cargoDate}
              onChange={setCargoDate}
              shouldHighlightWeekends
              locale="fa"
              add
              this
              style={{ zIndex: "1" }}
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <label>ساعت</label>
            <Input
              onChange={({ target }) =>
                setNewOrder(state => ({
                  ...state,
                  hour: target.value,
                }))
              }
              value={newOrder.hour}
              type="number"
            />
          </Grid>
          <Grid
            className={classes.colItem}
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <label>دقیقه</label>
            <Input
              onChange={({ target }) =>
                setNewOrder(state => ({
                  ...state,
                  minute: target.value,
                }))
              }
              value={newOrder.minute}
              type="number"
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      <h3> مکان بارگیری </h3>
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label> نام فرستنده</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                name: target.value,
              }))
            }
            value={newOrder.name}
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>شماره تلفن مبداء</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                phoneNumber: target.value,
              }))
            }
            value={newOrder.phoneNumber}
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>کدپستی مبداء</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                postalCodeOrigin: target.value,
              }))
            }
            value={newOrder.postalCodeOrigin}
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>آدرس</label>
          <TextareaAutosize
            style={{ border: "solid 1px #dcdcdc", height: 78 }}
            value={newOrder.originAddress}
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                originAddress: target.value,
              }))
            }
            rows={4}
            cols={4}
          />
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      <h3> مکان تخلیه </h3>
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label> نام گیرنده</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                transfereeName: target.value,
              }))
            }
            value={newOrder.transfereeName}
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>شماره تلفن مقصد</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                transfereePhoneNumber: target.value,
              }))
            }
            value={newOrder.transfereePhoneNumber}
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>کدپستی مقصد</label>
          <Input
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                postalCodeDis: target.value,
              }))
            }
            value={newOrder.postalCodeDis}
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>آدرس</label>
          <TextareaAutosize
            style={{ border: "solid 1px #dcdcdc", height: 78 }}
            value={newOrder.distinationAddress}
            onChange={({ target }) =>
              setNewOrder(state => ({
                ...state,
                distinationAddress: target.value,
              }))
            }
            rows={4}
            cols={4}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" direction="row-reverse">
        <Grid style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
            onClick={confirmAddressCargo}
            //   disabled={disabled}
          >
            مرحله بعد
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CargoAddressInfo;
