import React, { useState, useContext, useEffect } from "react";
//import { GoogleMap, LoadScript } from "@react-google-maps/api";
import Mapir from "mapir-react-component";
import { Grid, Button } from "@material-ui/core";
import { MainContext } from "../../../context/MainContext";
import Select from "react-select";
import useStyles from "../../../components/styles/styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../../constant/ApiService";
import { getRequestAutoComplete } from "../../../constant/ApiService";
import Api from "../../../constant/Api";


const NewOrder = () => {
  const classes = useStyles();
  const history = useHistory();

  const defaultCenter = {
    lat: 35.715298,
    lng: 51.404343,
  };
  const [defaultCenters, setDefaultCenters] = useState(defaultCenter);

  let {
    city,
    setCity,
    cityOption,
    setCityPos,
    cityPos,
    newOrder,
    setNewOrder,
  } = useContext(MainContext);

  const cityOption3 = item => {
    console.log(item,"cityOption3")
    getRequest( `${Api.GetCity}?CityName=${item}`).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.cityName,
          };
        });

      setCity(handleOptions);
      setCityPos(res && res.data.lists);
    });
  };

  // const cityOption3 = item => {
  //  // console.log(item,"cityOption3")
  //  if(!item.length)
  //     return;
      
  //  setTimeout(()=>{
  //   getRequestAutoComplete( `https://map.ir/search/v2/autocomplete?text=${item}`).then(res => {
  //     console.log(res,"cityOption3")
 
  //   const handleOptions =
  //       res &&
  //       res.data.value.map(item => {
  //         return {
  //           value: item.title,
  //           label: item.address,
  //         };
  //       });

  //     setCity(handleOptions);
  //     setCityPos(res && res.data.data.value);
  //   });
  //  },1000)
    
  // };
  const mapStyles = {
    height: "100vh",
    width: "100%",
    position: "relative",
  };

  useEffect(() => {
    cityOption();
  }, []);

  const confirmCitySelect = () => {
    if (!newOrder.origin) {
      toast.error("شهر مبدارا انتخاب کنید");
      return;
    }
    if (!newOrder.distination) {
      toast.error("شهر مقصد را انتخاب کنید");
      return;
    }
    history.push("/app/cargoAddressInfo");
  };

  const Map = Mapir.setToken({
    transformRequest: (url)=> {
        return {
            url: url,
            headers: { 
                'x-api-key': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjQwMzJhNDk0NTc4NDYzNjljMDU3NjUzNmU5NGI3NzQ0MTc1Yjc1MWI0NzAxOTMyYTU3MWU5ZjIwOGJmY2FiYTJhM2M3Y2JiZTFmZWI0YmY5In0.eyJhdWQiOiIxNjM2MiIsImp0aSI6IjQwMzJhNDk0NTc4NDYzNjljMDU3NjUzNmU5NGI3NzQ0MTc1Yjc1MWI0NzAxOTMyYTU3MWU5ZjIwOGJmY2FiYTJhM2M3Y2JiZTFmZWI0YmY5IiwiaWF0IjoxNjM5NjQ0NzgwLCJuYmYiOjE2Mzk2NDQ3ODAsImV4cCI6MTY0MjE1MDM4MCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.XGTanHNh9Wxh5n9GyE_CAG7US0D8r21BWSrY9t295Wd2pxbbXqtvJcChPh9Rd5O-1XgYLBMyb56vmPa-yUL9F4OL-sHc6iKe0ZNr2nAoPquZ2blV7kaDg2E2qeDLmyAePjvrgwRtEAcqDZG8O2VjjtFVSR0srYAkV0UHwKDExmYQ-kIoUqgPzU7OkaO1tr7G7eEIjMiG9aGsPM4Wuq7d_NI4qaYJ6OyIDPDyjv1OfrcEi87Jfk2ewLoC6Rg1dSRjw-Ri2hbIetPiX9B_yXG87U-dhyojpGiSaqCijCpeE0ELEWzEbh0BhppmK3CgeHf73AlKl01fPV2qpBS64ljgMQ', //Mapir access token
                'Mapir-SDK': 'reactjs'
            }
        }
 
    }
});

 console.log("cities",city)
  return (
    <Grid>
     {/* <LoadScript googleMapsApiKey="AIzaSyCYM9qydM4YGuslSoKYFl9lf65j1rfheK8"> 
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={defaultCenters}
        ></GoogleMap>
      </LoadScript> */}
      <Mapir
      zoom={[6]}    
       center={defaultCenters}
       Map={Map}
       >

      </Mapir>
      <Grid id="center-marker"></Grid>


      <Grid container justifyContent="space-between">
        <Grid
          className={classes.colItem}
          item
          xl={3}
          lg={3}
          md={3}
          sm={4}
          xs={4}
          style={{
            position: "absolute",
            top: "120px",
            right: "300px",
            width: "100%",
          }}
        >
          <Select
            onInputChange={cityOption3}
            onChange={({ target }) => setCity(target.value)}
            onChange={e => {
              setNewOrder({
                ...newOrder,
                origin: e,
              });
              let newPos = cityPos.filter(item => item.id == e.value);
              setDefaultCenters({ lat: newPos[0].lat, lng: newPos[0].lon });
            }}
            options={city}
            value={newOrder.origin}
            placeholder="شهر مبدا را انتخاب کنید"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={3}
          lg={3}
          md={3}
          sm={4}
          xs={4}
          style={{
            position: "absolute",
            top: "120px",
            right: "800px",
            width: "100%",
          }}
        >
          <Select
            onInputChange={cityOption3}
            // onChange={({ target }) => setCity(target.value)}
            onChange={e => {
              setNewOrder({
                ...newOrder,
                distination: e,
              });
              let newPos = cityPos.filter(item => item.id == e.value);
              setDefaultCenters({ lat: newPos[0].lat, lng: newPos[0].lon });
            }}
            options={city}
            value={newOrder.distination}
            placeholder="شهر مقصد را انتخاب کنید"
          />
        </Grid>
      </Grid>
      {newOrder.origin && newOrder.distination && (
        <Grid container style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            style={{
              color: "#fff",
              backgroundColor: "#3CD4A0",
              position: "absolute",
              top: "220px",
              right: "650px",
              width: "100px",
            }}
            onClick={confirmCitySelect}
          >
            تایید
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default NewOrder;
