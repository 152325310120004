export default {
  primary: {
    main: '#2980B9',
    contrastText: '#FFF'
  },
  info: {
    main: '#2FC9C6',
    contrastText: "#fff"
  },
  secondary: {
    main: '#c59428',
    contrastText: '#FFF'
  },
  warning: {
    main: '#f62525',
    contrastText: '#FFF'
  },
  success: {
    main: "#3CD4A0",
    contrastText: '#fff'
  },
}