import React, { useState, useContext, useEffect, useCallback } from "react";
import { Grid, TextareaAutosize, Button, Divider } from "@material-ui/core";
import Input from "../../../../components/utils/Input";
import CustomButton from "../../../../components/utils/CustomButton";
import CustomPopup from "../../../../components/utils/CustomPopup";
import useStyles from "../../../../components/styles/styles";
import NewAddressModal from "./NewAddressModal";
import { deleteRequest } from "../../../../constant/ApiService";
import Api from "../../../../constant/Api";
import { MainContext } from "../../../../context/MainContext";
import { useParams } from "react-router-dom";
import { getRequest } from "../../../../constant/ApiService";

const secondary = "#E74C3C";
const success = "#3CD4A0";

const Address = () => {
  let {
    detailAddress,
    setDetailAddress,
    detail,
    setDetail,
    detailDisAddress,
    setDetailDisAddress,
    detailOriginAddress,
    setDetailOriginAddress,
  } = useContext(MainContext);

  const {
    name,
    source,
    distination,
    transfereeName,
    mobile,
    transfereePhoneNumber,
  } = detailAddress;
  const { id } = useParams();

  const classes = useStyles();

  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
  });
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelDis, setOpenDelDis] = useState({
    show: false, // initial values set to false and null
    id: null,
  });
  const [openDis, setOpenDis] = useState(false);
  const handleDeleteAddress = useCallback(
    (idDel, type) => {
      deleteRequest(`${Api.DeleteCargoAddress}/${type}/${idDel}`);
      getRequest(Api.GetCargoDetail + "?Id=" + id).then(res => {
        setDetail(res && res.data);
      });
      setPopup({ show: false, id: null });
      setOpenDelDis({ show: false, id: null });
    },
    [detail],
  );

  let handleChangeOrigin = (e, i, name) => {
    let newFormValues = [...detailOriginAddress];
    newFormValues[i][name] = e.target.value;
    setDetailOriginAddress(newFormValues);
  };
  let handleChangeDis = (e, i, name) => {
    let newFormValues = [...detailDisAddress];
    newFormValues[i][name] = e.target.value;
    setDetailDisAddress(newFormValues);
  };

  useEffect(() => {
    if (detail) {
      setDetailAddress({
        ...detailAddress,
        name: detail.cargoOwnerName,
        mobile: detail.cargoOwnerPhoneNumber,
        source: detail.sourceAddress,
        distination: detail.destAddress,
        transfereeName: detail.transfereeName,
        transfereePhoneNumber: detail.transfereePhoneNumber,
        cargo: detail.title,
      });
    }
    if (detail) {
      let newDetailOrigin = [];
      detail.cargoLoadingLocations.length > 0 &&
        detail.cargoLoadingLocations.map((item, i) => {
          newDetailOrigin.push({
            id: item.id,
            address: item.address,
            postalCode: item.postalCode,
            priority: item.priority,
            tellNumber: item.phoneNumber,
          });
        });
      setDetailOriginAddress(newDetailOrigin);
    }
    if (detail) {
      let newDetailDis = [];
      detail.cargoDischargeLocations.length > 0 &&
        detail.cargoDischargeLocations.map((item, i) => {
          newDetailDis.push({
            id: item.id,
            address: item.address,
            postalCode: item.postalCode,
            priority: item.priority,
            tellNumber: item.phoneNumber,
          });
        });
      setDetailDisAddress(newDetailDis);
    }
  }, [detail]);

  return (
    <Grid container>
      <Grid
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
        className={classes.firstFiled}
        style={{ display: "flex", flexDirection: "column", padding: "5px" }}
      >
        <label>مبدا </label>
        <Input
          onChange={({ target }) =>
            setDetailAddress(state => ({
              ...state,
              source: target.value,
            }))
          }
          value={source}
          type="string"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>نام و نام خانوادگی</label>
        <Input
          onChange={({ target }) =>
            setDetailAddress(state => ({
              ...state,
              name: target.value,
            }))
          }
          value={name}
          type="text"
        />
      </Grid>
      <Grid
        className={classes.colItem}
        item
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
      >
        <label>شماره موبایل </label>
        <Input
          onChange={({ target }) =>
            setDetailAddress(state => ({
              ...state,
              mobile: target.value,
            }))
          }
          value={mobile}
          type="text"
        />
      </Grid>

      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            padding: "5px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          {/* <Button
            onClick={() => setOpenAdd(true)}
            variant="contained"
            style={{
              backgroundColor: "#3CD4A0",
              color: " #fff",
              width: 150,
              height: 36,
              marginTop: "20px",
            }}
          >
            آدرس جدید مبداء{" "}
          </Button> */}
          {openAdd ? (
            <NewAddressModal
              open={openAdd}
              setOpen={setOpenAdd}
              address={source}
              status={0}
              id={id}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      {detailOriginAddress.length > 0 &&
        detailOriginAddress.map((item, index) => {
          return (
            <Grid container>
              <Grid
                className={classes.colItem}
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
              >
                <label>آدرس</label>
                <TextareaAutosize
                  style={{
                    border: "solid 1px #dcdcdc",
                    height: 78,
                    borderRadius: "5px",
                  }}
                  value={item.address}
                  onChange={e => handleChangeOrigin(e, index, "address")}
                  rows={4}
                  cols={4}
                />
              </Grid>
              <Grid
                className={classes.colItem}
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
              >
                <label>کدپستی</label>
                <Input
                  value={item.postalCode}
                  type="number"
                  onChange={e => handleChangeOrigin(e, index, "postalCode")}
                />
              </Grid>
              <Grid
                className={classes.colItem}
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
              >
                <label>شماره تلفن</label>
                <Input
                  onChange={e => handleChangeOrigin(e, index, "tellNumber")}
                  value={item.tellNumber}
                  type="number"
                />
              </Grid>
              {/* <Grid
                className={classes.colItem}
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
              >
                <label>الویت</label>
                <Input
                  onChange={e => handleChangeOrigin(e, index, "priority")}
                  value={item.priority}
                  type="number"
                />
              </Grid> */}
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={12}
                xs={12}
                style={{
                  padding: "5px",
                  justifyContent: "flex-end",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {/* <CustomButton
                  onClick={() => setPopup({ show: true, id: item.id })}
                  variant="contained"
                  color={secondary}
                  txt="حذف"
                  txtColor="#fff"
                /> */}
              </Grid>
              {popup.show && popup.id ? (
                <CustomPopup
                  open={popup.show}
                  text="آیا از حذف آدرس مورد نظر اطمینان دارید؟"
                  setOpen={setPopup}
                  bgColor1={secondary}
                  bgColor2="inherit"
                  btnTxt1="#fff"
                  btnTxt2="inherit"
                  title="حذف آدرس"
                  click={() => handleDeleteAddress(popup.id, 0)}
                />
              ) : (
                ""
              )}
            </Grid>
          );
        })}
      <Grid container>
        <Grid container alignItems="center" justifyContent="center">
          <Divider style={{ width: "80%", margin: "20px 0" }} />
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <h4>جزییات مقصد</h4>
        </Grid>
        <Grid
          style={{ display: "flex", flexDirection: "column", padding: "5px" }}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          className={classes.firstFiled}
        >
          <label> مقصد</label>
          <Input
            onChange={({ target }) =>
              setDetailAddress(state => ({
                ...state,
                distination: target.value,
              }))
            }
            value={distination}
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نام و نام خانوادگی</label>
          <Input
            onChange={({ target }) =>
              setDetailAddress(state => ({
                ...state,
                transfereeName: target.value,
              }))
            }
            value={transfereeName}
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>شماره موبایل </label>
          <Input
            onChange={({ target }) =>
              setDetailAddress(state => ({
                ...state,
                transfereePhoneNumber: target.value,
              }))
            }
            value={transfereePhoneNumber}
            type="text"
          />
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            padding: "5px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          {/* <Button
            onClick={() => setOpenDis(true)}
            variant="contained"
            style={{
              backgroundColor: "#3CD4A0",
              color: " #fff",
              width: 150,
              height: 36,
              marginTop: "20px",
            }}
          >
            آدرس جدید مقصد
          </Button> */}
        {openDis ? (
          <NewAddressModal
            open={openDis}
            setOpen={setOpenDis}
            address2={distination}
            id={id}
            status={1}
          />
        ) : (
          ""
        )}
      </Grid>

      </Grid>
      {detailDisAddress.length > 0 &&
        detailDisAddress.map((item, index) => {
          return (
            <Grid container>
              <Grid
                className={classes.colItem}
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
              >
                <label>آدرس</label>
                <TextareaAutosize
                  style={{
                    border: "solid 1px #dcdcdc",
                    height: 78,
                    borderRadius: "5px",
                  }}
                  value={item.address}
                  onChange={e => handleChangeDis(e,index, "address")}
                  rows={4}
                  cols={4}
                />
              </Grid>
              <Grid
                className={classes.colItem}
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
              >
                <label>کدپستی</label>
                <Input
                 value={item.postalCode}
                 type="number"
                 onChange={e => handleChangeDis(e, index, "postalCode")}
                
                />
              </Grid>
              <Grid
                className={classes.colItem}
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
              >
                <label>شماره تلفن</label>
                <Input
                  value={item.tellNumber}
                  type="number"
                  onChange={e => handleChangeDis(e,index, "tellNumber")}
                />
              </Grid>
              {/* <Grid
                className={classes.colItem}
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
              >
                <label>الویت</label>
                <Input
                  onChange={e => handleChangeDis(index, e, "priority")}
                  value={item.priority}
                  type="number"
                />
              </Grid> */}
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={12}
                xs={12}
                style={{
                  padding: "5px",
                  justifyContent: "flex-end",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {/* <CustomButton
                  onClick={() => setOpenDelDis({ show: true, id: item.id })}
                  variant="contained"
                  color={secondary}
                  txt="حذف"
                  txtColor="#fff"
                /> */}
              </Grid>
              {openDelDis.show && openDelDis.id ? (
                <CustomPopup
                  open={openDelDis.show}
                  text="آیا از حذف آدرس مورد نظر اطمینان دارید؟"
                  setOpen={setOpenDelDis}
                  bgColor1={secondary}
                  bgColor2="inherit"
                  btnTxt1="#fff"
                  btnTxt2="inherit"
                  title="حذف آدرس"
                  click={() => handleDeleteAddress(openDelDis.id, 1)}
                />
              ) : (
                ""
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};

export default Address;
