export const INSURANCE = [
  { id: 1, name: "دارد" },
  { id: 2, name: "ندارد" },
];

export const TRUCKTYPE = [
  { value: 1, label: "تریلی" },
  { value: 3, label: "تک" },
  { value: 2, label: "جفت" },
  { value: 4, label: "خاور" },
];

export const TYPE = [
  { id: 1, name: "مبدا" },
  { id: 2, name: "مقصد" },
];

export const TYPECARGO = [
  { id: 1, title: "سوربن ترابر هوشمند" },
  { id: 2, title: "بهاره آسیا" },
];

export const changeStateCargoData = [
  { value: 1, label: "بار در انتظار" },
  { id: 2, label: "بار در حال انجام" },
  { id: 3, label: "به اتمام رسیده" },
];

export const changeStateCargo = [
  { value: 1, label: "تایید تخصیص بار توسط راننده"},
  { value: 2, label: "در انتظار صدور بارنامه" },
  { value: 3, label: "بارنامه صادر شده است" },
  { value: 4, label: "حرکت به سمت محل بارگیری" },
  { value: 5, label: "به محل بارگیری رسیدم"},
  { value: 6, label: "بارگیری انجام شده است" },
  { value: 7, label: "در حال حرکت به سمت محل تخلیه" },
  { value: 8, label: "به محل تخلیه رسیدم" },
  { value: 9, label: "تخلیه بار انجام شده است" },

];

export const TypeCustomer = [
  { value: 1, label: "حقیقی" },
  { value: 2, label: "حقوقی" },
  { value: 3, label: "متصدی" },
];
export const DEGREEDRIVER = [
  { value: 1, label: "سیکل" },
  { value: 2, label: " دیپلم" },
  { value: 3, label: "کاردانی" },
  { value: 7, label: "کارشناسی" },
  { value: 5, label: "کارشناسی ارشد" },
  { value: 6, label: "دکترا" },
];
export const STEPS = [
  "انتخاب شهر",
  "انتخاب آدرس",
  "جزییات محموله",
  "نوع بارگیر",
  "نوع ",
  "پیش نمایش اطلاعات",
];

// export const CargoStatea = [
//   "انتخاب شهر",
//   "انتخاب آدرس",
//   "جزییات محموله",
//   "نوع بارگیر",
//   "نوع ",
//   "پیش نمایش اطلاعات",
// ];
