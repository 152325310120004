import { Grid, Button } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import { TypeCustomer } from "../../constant/staticData";
import useStyles from "../../components/styles/styles";
import Input from "../../components/utils/Input";
import Api from "../../constant/Api";
import { useHistory } from "react-router-dom";
import { postRequest, getRequest } from "../../constant/ApiService";
import { toast } from "react-toastify";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { MainContext } from "../../context/MainContext";
import PageTitle from "../../components/PageTitle/PageTitle";

const NewTRansportation = () => {
  const classes = useStyles();
  const history = useHistory();
  const [image, setimage] = useState("");
  const [contractDate, setContractDate] = useState(null);
  const [NewTransportationData, setNewTransportationData] = useState({
    name: "",
    address: "",
    phone: "",
    tellNumber: "",
    managerName: "",
    managerPhone: "",
    contractNumber: "",
    userName: "",
    password: "",
  });

  let { city, setCity, cityOption } = useContext(MainContext);

  useEffect(() => {
    cityOption();
  }, []);

  const addNewTransportation = () => {
    if (!NewTransportationData.name) {
      toast.error("نام شرکت را وارد کنید");
      return;
    }
    if (!NewTransportationData.cityValue) {
      toast.error("شهر را وارد کنید");
      return;
    }
    if (!NewTransportationData.address) {
      toast.error("آدرس را وارد کنید");
      return;
    }
    if (!NewTransportationData.tellNumber) {
      toast.error("شماره تلفن را وارد کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(NewTransportationData.tellNumber)) {
      toast.error("شماره تلفن را درست وارد  کنید");
      return;
    }
    if (!NewTransportationData.managerName) {
      toast.error("نام مدیر را وارد کنید");
      return;
    }
    if (!NewTransportationData.managerPhone) {
      toast.error("شماره همراه مدیر را وارد کنید");
      return;
    }
    if (!/^[0-9]{11}$/.test(NewTransportationData.managerPhone)) {
      toast.error("شماره همراه مدیر را درست وارد  کنید");
      return;
    }
    if (!NewTransportationData.contractNumber) {
      toast.error("شماره قرارداد را وارد کنید");
      return;
    }
    if (!contractDate) {
      toast.error("تاریخ قرارداد را وارد کنید");
      return;
    }
    if (!NewTransportationData.userName) {
      toast.error("نام کاربری را وارد کنید");
      return;
    }
    if (!NewTransportationData.password) {
      toast.error("کلمه عبور را وارد کنید");
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(
        NewTransportationData.password,
      )
    ) {
      toast.error(
        "کلمه عبور باید شامل حروف بزرگ و کوچک ,عددوکاراکترهای خاص وحداقل ۶رقم باشد",
      );
      return;
    }
    let body = {
      name: NewTransportationData.name,
      cityId:
        NewTransportationData.cityValue &&
        NewTransportationData.cityValue.value,
      address: NewTransportationData.address,
      contractImage: image.toString(),
      tellNumber: NewTransportationData.tellNumber,
      managerName: NewTransportationData.managerName,
      managerPhone: NewTransportationData.managerPhone,
      contractNumber: NewTransportationData.contractNumber,
      contractDate:
        contractDate &&
        `${contractDate.year}/${contractDate.month}/${contractDate.day}`,
      wagePecent: 2,
      userName: NewTransportationData.userName,
      password: NewTransportationData.password,
    };
    postRequest(Api.CreateTransportationCompany, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/transportationCompany");
      }
    });
  };

  const uploadFile = async event => {
    const file = event.target.files[0];
    const base64 = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
    setimage(base64);
  };
  return (
    <Grid>
      <PageTitle title="ثبت باربری" />
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نام </label>
          <Input
            name="name"
            value={NewTransportationData.name}
            onChange={({ target }) =>
              setNewTransportationData(state => ({
                ...state,
                name: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>شهر</label>
          <Select
            onChange={({ target }) => setCity(target.value)}
            onChange={e =>
              setNewTransportationData({
                ...NewTransportationData,
                cityValue: e,
              })
            }
            options={city}
            value={NewTransportationData.cityValue}
            placeholder="شهر را انتخاب کنید"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>آدرس </label>
          <Input
            name="address"
            value={NewTransportationData.address}
            onChange={({ target }) =>
              setNewTransportationData(state => ({
                ...state,
                address: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>شماره تلفن </label>
          <Input
            name="tellNumber"
            value={NewTransportationData.tellNumber}
            onChange={({ target }) =>
              setNewTransportationData(state => ({
                ...state,
                tellNumber: target.value,
              }))
            }
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نام مدیر </label>
          <Input
            name="managerName"
            value={NewTransportationData.managerName}
            onChange={({ target }) =>
              setNewTransportationData(state => ({
                ...state,
                managerName: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>عکس </label>
          <Input
            name="image"
            // value={image}
            onChange={uploadFile}
            type="file"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>تلفن مدیر </label>
          <Input
            name="managerPhone"
            value={NewTransportationData.managerPhone}
            onChange={({ target }) =>
              setNewTransportationData(state => ({
                ...state,
                managerPhone: target.value,
              }))
            }
            type="number"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>شماره قرارداد </label>
          <Input
            name="contractNumber"
            value={NewTransportationData.contractNumber}
            onChange={({ target }) =>
              setNewTransportationData(state => ({
                ...state,
                contractNumber: target.value,
              }))
            }
            type="number"
          />
        </Grid>
        <Grid
          className={`${classes.colItem} ${classes.datePicker}`}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>تاریخ قرارداد </label>
          <DatePicker
            name="contractDate"
            value={contractDate}
            onChange={setContractDate}
            shouldHighlightWeekends
            locale="fa"
            add
            this
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نام کاربری </label>
          <Input
            name="userName"
            value={NewTransportationData.userName}
            onChange={({ target }) =>
              setNewTransportationData(state => ({
                ...state,
                userName: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>رمز عبور</label>
          <Input
            name="password"
            value={NewTransportationData.password}
            onChange={({ target }) =>
              setNewTransportationData(state => ({
                ...state,
                password: target.value,
              }))
            }
            type="password"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        justifyContent="space-between"
        direction="row-reverse"
      >
        {/* <Grid  style={{ display: "flex" }}> */}
        <Button
          variant="contained"
          style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
          onClick={addNewTransportation}
          // disabled={disabled}
        >
          ثبت{" "}
        </Button>
      </Grid>
    </Grid>
  );
};
export default NewTRansportation;
