import React, { useEffect, useState, useContext, useCallback } from "react";

import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import { MainContext } from "../../../context/MainContext";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Api from "../../../constant/Api";
import { useHistory } from "react-router-dom";
import { getRequest } from "../../../constant/ApiService";
import { useParams } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useStyles from "../../../components/styles/styles";
import Input from "../../../components/utils/Input";

const CrmUser = () => {
  const [items, setItems] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  const [crmUserSearch, setCrmUserSearch] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    nationalId: "",
  });
  let {
    handleChangeRowsPerPage,
    openPopup,
    setOpenPopup,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    detailCrmUser,
    setDetailCrmUser,
  } = useContext(MainContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const getCrmUserList = () => {
    getRequest(
      `${Api.GetCrmUser}?FirstName=${
        crmUserSearch.firstName
      }&NationalId=${
        crmUserSearch.nationalId
      }&LastName=${crmUserSearch.lastName}&PhoneNumber=${
        crmUserSearch.phoneNumber
      }&PageNumber=${page + 1}&PageSize=${rowsPerPage}`,
    ).then(res => {
      setItems(res && res.data);
    });
  };
  useEffect(() => {
    getCrmUserList();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getCrmUserList();
  };
  return (
    <Grid>
      <PageTitle title="لیست کابران   " />
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نام کاربر </label>
          <Input
            name="firstName"
            value={crmUserSearch.firstName}
            onChange={({ target }) =>
              setCrmUserSearch(state => ({
                ...state,
                firstName: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نام خانوادگی کاربر </label>
          <Input
            name="lastName"
            value={crmUserSearch.lastName}
            onChange={({ target }) =>
            setCrmUserSearch(state => ({ ...state, lastName: target.value }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>شماره تلفن کاربر </label>
          <Input
            name="phoneNumber"
            value={crmUserSearch.phoneNumber}
            onChange={({ target }) =>
            setCrmUserSearch(state => ({ ...state, phoneNumber: target.value }))
            }
            type="tel"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>کد ملی </label>
          <Input
            name="nationalId"
            value={crmUserSearch.nationalId}
            onChange={({ target }) =>
            setCrmUserSearch(state => ({ ...state, nationalId: target.value }))
            }
            type="number"
          />
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          direction="row-reverse"
          style={{ padding: "20px 0" }}
        >
          <Grid style={{ display: "flex" }}>
            <Button
              variant="contained"
              style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
              onClick={getCrmUserList}
            >
              جستجو کنید
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>ردیف</TableCell>
              <TableCell>شناسه</TableCell>
              <TableCell> نام</TableCell>
              <TableCell>نام خانوادگی</TableCell>
              <TableCell>شناسه ملی</TableCell>
              <TableCell>شماره همراه</TableCell>
              <TableCell>ایمیل</TableCell>
              <TableCell>شناسه کاربری</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.lists &&
              items.lists.length > 0 &&
              items.lists.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailcrmUser/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailcrmUser/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.id}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailcrmUser/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.firstName}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailcrmUser/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.lastName}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailcrmUser/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.nationalId}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailcrmUser/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.phoneNumber}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailcrmUser/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.email}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/detailcrmUser/${item.id}`,
                        state: item,
                      });
                    }}
                  >
                    {item.userName}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage=""
        rowsPerPageOptions={[]}
        component="div"
        count={items && items.count > 0 ? items.count : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
};
export default CrmUser;
