import React from "react";
import  { useEffect, useState } from "react";
import { Grid, Divider, Button, TextField } from "@material-ui/core";
import PageTitle from "../components/PageTitle";
import useStyles from "../components/styles/styles";
import Api from "../constant/Api";
import { getRequest } from "../constant/ApiService";
const Dashboard = () => {

  const classes = useStyles();
  const [dashboard, setDashboard] = useState(null);

  useEffect(() => {
      // getRequest(Api.GetDashboardReport).then(res => {
      //   console.log(res)
      //   setDashboard(res && res.data);
      // });
    
  }, []);
  return (
    <Grid>
      <PageTitle title="به پنل مدیریت بارمپ خوش آمدید" />

      <Grid container>

        <Grid container alignItems="center" justifyContent="space-between">
          <Grid
                className={classes.colItem}
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
              >
                <label>تعداد کاربران بارمپ (حقیقی،حقوقی و متصدی)</label>

                <TextField
                id="standard-read-only-input"
                defaultValue= "24 کاربر"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              /> 
          </Grid>

          <Grid
              className={classes.colItem}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <label>تعداد سفیران</label>

              <TextField
              id="standard-read-only-input"
              defaultValue="132 سفیر"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
        </Grid>
      </Grid>


      <Grid container>

<Grid container alignItems="center" justifyContent="space-between">
  <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label>بارهای در انتظار تایید</label>

        <TextField
        id="standard-read-only-input"
        defaultValue= "33 بار"
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
      /> 
  </Grid>

  <Grid
      className={classes.colItem}
      item
      xl={6}
      lg={6}
      md={6}
      sm={12}
      xs={12}
    >
      <label> بارهای در حال انجام(فعال)</label>

      <TextField
      id="standard-read-only-input"
      defaultValue=" 34 بار"
      variant="outlined"
      InputProps={{
        readOnly: true,
      }}
     /> 
    </Grid>
</Grid>
</Grid>
<Grid container>

<Grid container alignItems="center" justifyContent="space-between">
  <Grid
        className={classes.colItem}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <label> بارهای به اتمام رسیده</label>

        <TextField
        id="standard-read-only-input"
        defaultValue= "113 بار "
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
      /> 
  </Grid>

  <Grid
      className={classes.colItem}
      item
      xl={6}
      lg={6}
      md={6}
      sm={12}
      xs={12}
      
    >
      <label>بار های رد شده (غیر فعال) </label>

      <TextField
      id="standard-read-only-input"
      defaultValue="14 بار"
      variant="outlined"
      InputProps={{
        readOnly: true,
      }}
     /> 
    </Grid>
</Grid>
</Grid>
     
    </Grid>
  );
};

export default Dashboard;
