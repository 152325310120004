import { makeStyles } from "@material-ui/core/styles";
import notFoundImg from "../../assets/images/notFound2.jpeg";

const useStyles = makeStyles(theme => ({
  formControl: {
    padding: "6px 8px 8px 3px",
    borderRadius: "5px",
    border: "solid 1px #dcdcdc",
    backgroundColor: "#ffffff",
    outline: "none",
    height: 38,
  },
  firstFiled: {
    width: "100%",
    "& .css-2b097c-container": {
      width: "100%",
    },
  },
  datePicker: {
    "& .DatePicker__input": {
      padding: "6px 8px 8px 3px",
      borderRadius: "5px",
      border: "solid 1px #dcdcdc",
      backgroundColor: "#ffffff",
      outline: "none",
      height: 38,
      width: "100%",
    },
  },
  colItem: {
    display: "flex",
    flexDirection: "column",
    padding: 5,
    "& .DatePicker": {
      zIndex: "unset !important",
    },
  },
  modalPop: {
    width: "30%",
    margin: "100px auto",
    padding: "15px 20px",
    borderRadius: 15,
    backgroundColor: "#fff",
    height: "35%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  newAddressModal: {
    margin: "100px auto",
    padding: "15px 20px",
    borderRadius: 15,
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "55%",
  },
  ////////////NotFound////////////////////
  root404: {
  //  backgroundColor:"#e7f0fe",
   height:'90%',
   color:'rgba(0, 0, 0, 0.38)'
  },
}));

export default useStyles;
