import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from "@material-ui/core";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Api from "../../../constant/Api";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../../context/MainContext";
import { useParams } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import DriversOfCargoListModal from "./DriversOfCargoListModal";
import BarnameModal from "./BarnameModal";
import ChangeStateCargoModel from "./ChangeStateCargoModel";
import CargoDriversModel from "./CargoDriversModel";
import { deleteRequest, getRequest } from "../../../constant/ApiService";

const Transactions = () => {
  const [driverOfCargoData, setDriverOfCargoData] = useState([]);
  let {
    handleChangeRowsPerPage,
    handleChangePage,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
  } = useContext(MainContext);
  const [items, setItems] = useState([]);
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [barId, setBarId] = useState(null);
  const [openPopupBarname, setOpenPopupBarname] = useState(false);
  const [openPopupChangeStateCargo, setopenPopupChangeStateCargo] = useState(false);
  const [openPopupCargoDrivers, setopenPopupCargoDrivers] = useState(false);
  const history = useHistory();
  const [approvedDriver, setApprovedDriver] = useState(null);
  const handleClick = (event,id)=> {
    setAnchorEl(event.currentTarget);
    setBarId(id)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuDetail = () => {
    return (    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "20ch",
        },
      }}
    >
      <MenuItem selected="Pyxis" onClick={handleClose}>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push({
              pathname: `/app/transactionsDetail/${barId}`,
              state: { detailData: "inProgressData" },
            });
          }}
        >
          پیگیری سفارش
        </Button>
      </MenuItem>
      <MenuItem selected="Pyxis" onClick={handleClose}>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="info"
          onClick={() => {
            // console.log(item)  
            setBarId(barId);
            setOpenPopupBarname(true);
          }}
        >
          ثبت بارنامه
        </Button>
      </MenuItem>
      <MenuItem selected="Pyxis" onClick={handleClose}>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="secondary"
          onClick={() => deleteCargoFunc(barId)}
        >
          رد درخواست
        </Button>
      </MenuItem>
      <MenuItem selected="Pyxis" onClick={handleClose}>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="success"
          onClick={() => {
            // console.log(item)  
            setBarId(barId);
            setopenPopupChangeStateCargo(true);
          }}
        >
          وضعیت بار
        </Button>
      </MenuItem>
      <MenuItem selected="Pyxis" onClick={handleClose}>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="success"
          onClick={() => {
            // console.log(item)  
            setBarId(barId);
            setopenPopupCargoDrivers(true);
          }}
        >
          راننده ها 
        </Button>
      </MenuItem>
    </Menu>);
  }
  const renderDriverName = item => {
    if (!item.isReserved)
      return <TableCell>در حال حاضر راننده ای نپذیرفته است</TableCell>;

    if (approvedDriver) {
      return (
        <TableCell style={{ color: "blue", cursor: "pointer" }}>
          <span
            onClick={() => {
              setSelectedItem(item);
              setOpenPopup(true);
            }}
          >
            {approvedDriver}
          </span>
        </TableCell>
      );
    }

    return (
      <TableCell style={{ color: "blue", cursor: "pointer" }}>
        <span
          onClick={() => {
            setSelectedItem(item);
            setOpenPopup(true);
          }}
        >
          {!!item.driverName.trim().length ? item.driverName : "انتخاب راننده"}
        </span>
      </TableCell>
    );
  };

  useEffect(() => {
    getRequest(Api.GetInProgressCargoes).then(res => {
      setItems(res && res.data.lists);
    });
  }, []);
  
  const deleteCargoFunc = id => {
    deleteRequest(`${Api.DeleteCargo}/${id}`);
  };

  
  let rowStop = false;
  return (
    <Grid>
      <PageTitle title="بارهای در حال انجام" />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 150 }}>ردیف</TableCell>
              <TableCell style={{ minWidth: 150 }}>شناسه</TableCell>
              <TableCell>مشتری</TableCell>
              <TableCell style={{ minWidth: 150 }}>تحویل گیرنده</TableCell>
              <TableCell>بار</TableCell>
              <TableCell style={{ minWidth: 150 }}>نوع کامیون</TableCell>
              <TableCell style={{ minWidth: 200 }}>
                تاریخ و ساعت بارگیری
              </TableCell>
              <TableCell style={{ minWidth: 200 }}>
                تاریخ و ساعت ثبت سفارش
              </TableCell>
              <TableCell style={{ minWidth: 200 }}>راننده منتخب</TableCell>
              <TableCell>اقدام</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.length > 0 &&
              items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/inProgressTransactionDetail/${item.id}`,
                        state: { detailData: "inProgressData" },
                      });
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/inProgressTransactionDetail/${item.id}`,
                        state: { detailData: "inProgressData" },
                      });
                    }}
                  >
                    {item.id}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/inProgressTransactionDetail/${item.id}`,
                        state: { detailData: "inProgressData" },
                      });
                    }}
                  >
                    {item.cargoOwnerName}
                    <br />
                    {item.cargoOwnerPhoneNumber}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/inProgressTransactionDetail/${item.id}`,
                        state: { detailData: "inProgressData" },
                      });
                    }}
                  >
                    {item.transfereeName}
                    <br />
                    {item.transfereePhoneNumber}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/inProgressTransactionDetail/${item.id}`,
                        state: { detailData: "inProgressData" },
                      });
                    }}
                  >
                    {item.title}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/inProgressTransactionDetail/${item.id}`,
                        state: { detailData: "inProgressData" },
                      });
                    }}
                  >
                    {item.truckTypeName}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/inProgressTransactionDetail/${item.id}`,
                        state: { detailData: "inProgressData" },
                      });
                    }}
                  >
                    {item.persianLoadingDateTime}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/app/inProgressTransactionDetail/${item.id}`,
                        state: { detailData: "inProgressData" },
                      });
                    }}
                  >
                    {item.persianCreated}
                  </TableCell>
                  {renderDriverName(item)}

                  <TableCell>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event)=>{
                        handleClick(event,item.id)
                      }
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>
                     {renderMenuDetail()}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage=""
        rowsPerPageOptions={[]}
        component="div"
        count={items && items.length > 0 ? items.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {openPopup && (
        <DriversOfCargoListModal
          driverOfCargoData={selectedItem}
          setApprovedDriver={setApprovedDriver}
          open={openPopup}
          setOpen={setOpenPopup}
        />
      )}

       {openPopupBarname && (
        <BarnameModal         
          open={openPopupBarname}
          setOpen={setOpenPopupBarname}
          barId={barId}
        />
      )}

      {openPopupChangeStateCargo && (
        <ChangeStateCargoModel         
          open={openPopupChangeStateCargo}
          setOpen={setopenPopupChangeStateCargo}
          barId={barId}
        />
      )}

     {openPopupCargoDrivers && (
        <CargoDriversModel         
          open={openPopupCargoDrivers}
          setOpen={setopenPopupCargoDrivers}
          barId={barId}
        />
      )}
    </Grid>
  );
};

export default Transactions;
