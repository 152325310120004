import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
// components
import Layout from "./Layout";
// pages
import Login from "../pages/login";
// context
import { useUserState } from "../context/UserContext";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "./tools/ThemeProvider";
import RTL from "./tools/RTL";
import { ToastContainer, toast } from "react-toastify";
import NotFound from "../pages/notFound/NotFound";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <Router>
      <ThemeProvider>
        <RTL>
          <CssBaseline />
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/app/dashboard" />}
            />
            <Route
              exact
              path="/app"
              render={() => <Redirect to="/app/dashboard" />}
            />

            <PrivateRoute path="/app" component={Layout} />
            <PublicRoute path="/login" component={Login} />
            <Route component={NotFound} />
          </Switch>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={true}
            // // newestOnTop={true}
            closeOnClick
            // rtl={true}
            // pauseOnFocusLoss
            // draggable
            pauseOnHover
          />
        </RTL>
      </ThemeProvider>
    </Router>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
