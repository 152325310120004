import { Grid, Divider, Button, TextField } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "../../components/styles/styles";
import { useParams } from "react-router-dom";
import { MainContext } from "../../context/MainContext";
import { getRequest, postRequest } from "../../constant/ApiService";
import Api from "../../constant/Api";
import Select from "react-select";
import { changeStateCargoData } from "../../constant/staticData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

const TransactionsDetail = props => {
  const classes = useStyles();
  const { id } = useParams();
  const [changeState, setChangeState] = useState("");
  const history = useHistory();

  const transactionData = props.location.state.detailData;

  let { transactionsDetailData, setTransactionsDetailData } = useContext(
    MainContext,
  );
  useEffect(() => {
    if (transactionData == "finishedData") {
      getRequest(Api.GetCargoDetail + "?Id=" + id).then(res => {
        setTransactionsDetailData(res && res.data);
      });
    }
  }, [id]);

  useEffect(() => {
    if (transactionData == "inProgressData") {
      getRequest(Api.GetCargoDetail + "?Id=" + id).then(res => {
        setTransactionsDetailData(res && res.data);
      });
    }
  }, [id]);
  useEffect(() => {
    if (transactionData == "cancelData") {
      getRequest(Api.GetCargoDetail + "?Id=" + id).then(res => {
        setTransactionsDetailData(res && res.data);
      });
    }
  }, [id]);

  const handleChangeStateCargo = e => setChangeState(e.value);
  const handleChangeStateOptions = changeStateCargoData.map(item => {
    return {
      value: item.id,
      label: item.label,
    };
  });

  const confirmChangeStateCargo = () => {
    let body = {
      id: id,
      toState: changeState.value,
    };
    postRequest(Api.ChangeCancelCargoToAnotherState, body).then(res =>
      toast.success(res.message),
    );
  };

  return (
    <Grid container>
      <PageTitle
        title={
          transactionsDetailData &&
          transactionsDetailData.cargoState &&
          transactionsDetailData.cargoState.title
        }
      />
      <Grid container>
        <h2>جزییات بار</h2>
      </Grid>

      <Grid style={{ display: "flex" }}>
      <Button
          style={{ width: "100%" }}
          variant="contained"
          color="secondary"
        //  onClick={() => deleteCargoFunc(barId)}
        >
          ویرایش
        </Button>
      </Grid>
     
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      <Grid container>
        <Grid container alignItems="center" justifyContent="space-between">
          <h3>جزییات آدرس</h3>
          <Grid container>
            <Grid
              className={classes.colItem}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <label>نام و نام خانوادگی فرستنده :</label>

              <TextField
              id="standard-read-only-input"
              defaultValue={ (transactionsDetailData &&
                transactionsDetailData.cargoOwnerName !== " ") ||
              transactionsDetailData.cargoOwnerName !== null
                ? transactionsDetailData.cargoOwnerName
                : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              className={classes.colItem}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <label>شماره موبایل:</label>

              <TextField
              id="standard-read-only-input"
              defaultValue={transactionsDetailData &&
                transactionsDetailData.cargoOwnerPhoneNumber &&
                transactionsDetailData.cargoOwnerPhoneNumber}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>مبدا:</label>
              <TextField
              id="standard-read-only-input"
              defaultValue={transactionsDetailData.sourceAddress && transactionsDetailData.sourceAddress}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            {transactionsDetailData &&
            !transactionsDetailData.cargoLoadingLocations !== [] ? (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.firstFiled}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                }}
              >
                {/* <label>آدرس :</label>
                <label>اولویت :</label> */}
              </Grid>
            ) : (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.firstFiled}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                }}
              >
                {/* <label>{`آدرس`}</label>
                <label>{`اولویت`} </label> */}

                {transactionsDetailData &&
                transactionsDetailData.cargoLoadingLocations
                  ? transactionsDetailData.cargoLoadingLocations.map(
                      (item, index) => (
                        <>
                          <label>{`آدرس‌ :${item.address}`}</label>
                          <label>{`اولویت :${item.priority}`} </label>
                        </>
                      ),
                    )
                  : ""}
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>نام و نام خانوادگی گیرنده :</label>
              <TextField
              id="standard-read-only-input"
              defaultValue={transactionsDetailData.transfereeName && transactionsDetailData.transfereeName}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              className={classes.colItem}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <label>شماره موبایل:</label>
             <TextField
              id="standard-read-only-input"
              defaultValue={transactionsDetailData.transfereePhoneNumber && transactionsDetailData.transfereePhoneNumber}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>

            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>مقصد:</label>
              <TextField
              id="standard-read-only-input"
              defaultValue={transactionsDetailData.destAddress}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             />
            </Grid>
            {transactionsDetailData &&
            !transactionsDetailData.cargoDischargeLocations !== [] ? (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.firstFiled}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                }}
              >
                {/* <label>آدرس :</label>
                <label>اولویت :</label> */}
              </Grid>
            ) : (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.firstFiled}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                }}
              >
                {transactionsDetailData &&
                transactionsDetailData.cargoDischargeLocations
                  ? transactionsDetailData.cargoDischargeLocations.map(
                      (item, index) => (
                        <>
                          <label>{`آدرس${item.address}`}</label>
                          <label>{`اولویت${item.priority}`} </label>
                        </>
                      ),
                    )
                  : ""}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Grid container>
          <h3>جزییات بار</h3>
          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>بار:</label>

              <TextField
              id="standard-read-only-input"
              defaultValue={transactionsDetailData &&
                transactionsDetailData.title &&
                transactionsDetailData.title}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>وزن محموله:</label>
                <TextField
              id="standard-read-only-input"
              defaultValue={`${transactionsDetailData &&
                transactionsDetailData.weight} تن`}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>بسته بندی:</label>
                 <TextField
              id="standard-read-only-input"
              defaultValue={transactionsDetailData &&
                transactionsDetailData.packageType != null
                  ? transactionsDetailData.packageType.title
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>تعداد محموله:</label>
                <TextField
              id="standard-read-only-input"
              defaultValue={transactionsDetailData && transactionsDetailData.number != null
                ? transactionsDetailData.number
                : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>عرض محموله:</label>
               <TextField
              id="standard-read-only-input"
              defaultValue={ transactionsDetailData && transactionsDetailData.width != null
                ? transactionsDetailData.width
                : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>طول محموله:</label>
                <TextField
              id="standard-read-only-input"
              defaultValue={  transactionsDetailData && transactionsDetailData.height != null
                ? transactionsDetailData.height
                : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>حجم محموله:</label>
               <TextField
              id="standard-read-only-input"
              defaultValue={    transactionsDetailData && transactionsDetailData.volume != null
                ? transactionsDetailData.volume
                : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>وضعیت محموله:</label>

              <TextField
              id="standard-read-only-input"
              defaultValue={   transactionsDetailData && transactionsDetailData.status != null
                ? transactionsDetailData.status
                : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label> ارزش بیمه (میلیون تومان):</label>
              <TextField
              id="standard-read-only-input"
              defaultValue={   transactionsDetailData &&
                transactionsDetailData.insuranceAmount != null
                  ? transactionsDetailData.insuranceAmount
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>روباری‌ :</label>  
                <TextField
              id="standard-read-only-input"
              defaultValue={"ندارد"}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label> توضیحات :</label>
                <TextField
              id="standard-read-only-input"
              defaultValue={ transactionsDetailData &&
                transactionsDetailData.description != null
                  ? transactionsDetailData.description
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Grid container>
          <h3> بارگیر</h3>
          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label> بارگیر:</label>
                <TextField
              id="standard-read-only-input"
              defaultValue={ transactionsDetailData && transactionsDetailData.truck != null
                ? transactionsDetailData.truck.title
                : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>نوع بارگیر:</label>
               <TextField
              id="standard-read-only-input"
              defaultValue={  transactionsDetailData &&
                transactionsDetailData.carrierType != null
                  ? transactionsDetailData.carrierType.title
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Grid container>
          <h3> تاریخ و ساعت بارگیری</h3>
          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>تاریخ بارگیری:</label>
               <TextField
              id="standard-read-only-input"
              defaultValue={  transactionsDetailData &&
                transactionsDetailData &&
                transactionsDetailData.loadingDate != null
                  ? transactionsDetailData.loadingDate
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>ساعت بارگیری:</label>
              <TextField
              id="standard-read-only-input"
              defaultValue={  transactionsDetailData &&
                transactionsDetailData &&
                transactionsDetailData.loadingTime != null
                  ? transactionsDetailData.loadingTime
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>تاریخ  :</label>
               <TextField
              id="standard-read-only-input"
              defaultValue={  transactionsDetailData &&
                transactionsDetailData.createdDate != null
                  ? transactionsDetailData.createdDate
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>ساعت :</label>

              <TextField
              id="standard-read-only-input"
              defaultValue={   transactionsDetailData &&
                transactionsDetailData.createdTime != null
                  ? transactionsDetailData.createdTime
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Grid container>
          <h3> قیمت</h3>

          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>قیمت :</label>
               <TextField
              id="standard-read-only-input"
              defaultValue={     transactionsDetailData &&
                transactionsDetailData.cargoPrice != null
                  ? transactionsDetailData.cargoPrice.cargoOwnerPrice
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>قیمت راننده :</label>
                <TextField
              id="standard-read-only-input"
              defaultValue={      transactionsDetailData &&
                transactionsDetailData.cargoPrice != null
                  ? transactionsDetailData.cargoPrice.driverPrice
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>قیمت :</label>

              <TextField
              id="standard-read-only-input"
              defaultValue={       transactionsDetailData &&
                transactionsDetailData.cargoPrice != null
                  ? transactionsDetailData.cargoPrice.realPrice
                  : ""}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
             /> 
            </Grid>

          </Grid>
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        {/* <Grid container>
          <h3> قیمت</h3>

          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{` قیمت :${
                transactionsDetailData &&
                transactionsDetailData.cargoPrice != null
                  ? transactionsDetailData.cargoPrice.cargoOwnerPrice
                  : ""
              }`}</label>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{` قیمت راننده :${
                transactionsDetailData &&
                transactionsDetailData.cargoPrice != null
                  ? transactionsDetailData.cargoPrice.driverPrice
                  : ""
              }`}</label>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.firstFiled}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{` قیمت :${
                transactionsDetailData &&
                transactionsDetailData.cargoPrice != null
                  ? transactionsDetailData.cargoPrice.realPrice
                  : ""
              }`}</label>
            </Grid>
          </Grid>
        </Grid> */}
        {transactionData == "cancelData" ? (
          <>
            <Divider style={{ width: "100%", margin: "10px 0" }} />
            <Grid container>
              <Grid
                className={classes.colItem}
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
              >
                <h3> تغییر وضعیت بار </h3>
                <Select
                  onChange={handleChangeStateCargo}
                  options={handleChangeStateOptions}
                  defaultValue={handleChangeStateOptions[0]}
                  placeholder="تغییر وضعیت بار را انتخاب کنید"
                />
              </Grid>
              <Grid
                className={classes.colItem}
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{
                  paddingTop: "60px",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    color: "#fff",
                    backgroundColor: "#3CD4A0",
                    width: "100px",
                  }}
                  onClick={confirmChangeStateCargo}
                >
                  ثبت{" "}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};
export default TransactionsDetail;
