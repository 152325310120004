import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// styles
import useStyles from "./styles";
// logo
import logo from "./logo.svg";
// context
import { loginUser, useUserDispatch } from "../../context/UserContext";
import axios from "axios";
import { BASEURL } from "../../constant/Api";
import { toast } from "react-toastify";
import { loginReqest } from "../../constant/ApiService";
import Api from "../../constant/Api";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  const loginClick = () => {
    setIsLoading(true);
    let body = {
      userNameOrEmail: loginValue,
      password: passwordValue,
    };
    loginReqest(Api.LoginByEmailUserName, body).then(res => {
      if (res && res.data.succeeded && res.data.succeeded == true) {
        loginUser(
          userDispatch,
          loginValue,
          passwordValue,
          props.history,
          setIsLoading,
          setError,
          res.data.data.token,
        );
      } else {
        setIsLoading(false);
        toast.error(res?.data.errors[0].error);
      }
    });
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>بارمپ</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="ورود" classes={{ root: classes.tab }} />
          </Tabs>
          <React.Fragment>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={e => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="ایمیل"
              type="email"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={e => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="پسورد"
              type="password"
              fullWidth
            />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={
                    loginValue.length === 0 || passwordValue.length === 0
                  }
                  onClick={() => {
                    loginClick();
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  ورود
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
