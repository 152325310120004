import React, { useEffect, useState, useContext, useCallback } from "react";

import { Button, Grid, TextareaAutosize } from "@material-ui/core";

import PageTitle from "../components/PageTitle/PageTitle";
import Api from "../constant/Api";
import { useHistory } from "react-router-dom";
import { getRequest } from "../constant/ApiService";
import { useParams } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useStyles from "../components/styles/styles";
import Input from "../components/utils/Input";
import { postRequest } from "../constant/ApiService";
import { toast } from "react-toastify";

const AboutUs = () => {
  const [aboutUsData, setAboutUsData] = useState([]);
  const classes = useStyles();

  const getAboutUs = () => {
    getRequest(`${Api.GetAboutUs}`).then(res => {
      setAboutUsData(res && res.data.aboutUs);
    });
  };

  const updatAboutUsFunc = () => {
    let body = {
      aboutUs: aboutUsData,
    };
    postRequest(Api.CreateAboutUs, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        getAboutUs();
      }
    });
  };
  const changeAboutUsData = e => {
    setAboutUsData(e.target.value);
  };
  useEffect(() => {
    getAboutUs();
  }, []);
  return (
    <Grid container>
      <PageTitle title="درباره ما" />
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        <TextareaAutosize
          onChange={changeAboutUsData}
          maxRows={300}
          value={aboutUsData}
          style={{ width: "100%", padding: "10px", lineHeight: "30px" }}
          className={classes.formControl}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        container
        justifyContent="space-between"
        direction="row-reverse"
      >
        <Button
          variant="contained"
          style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
          onClick={updatAboutUsFunc}
        >
          ثبت{" "}
        </Button>
      </Grid>
    </Grid>
  );
};
export default AboutUs;
