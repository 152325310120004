import React, { useEffect, useState, useContext, useCallback } from "react";

import { Button, Grid, TextareaAutosize } from "@material-ui/core";

import PageTitle from "../components/PageTitle/PageTitle";
import Api from "../constant/Api";
import { useHistory } from "react-router-dom";
import { getRequest } from "../constant/ApiService";
import { useParams } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useStyles from "../components/styles/styles";
import Input from "../components/utils/Input";
import { postRequest } from "../constant/ApiService";
import { toast } from "react-toastify";

const Rule = () => {
  const classes = useStyles();

  const [ruleData, setRuleData] = useState({
    driverRule: "",
    cargoOwnerRule: "",
    operatorRule: "",
  });

  const getRule = () => {
    getRequest(`${Api.GetRule}`).then(res => {
      setRuleData({
        ...ruleData,
        driverRule: res && res.data.driverRule,
        cargoOwnerRule: res && res.data.cargoOwnerRule,
        operatorRule: res && res.data.operatorRule,
      });
    });
  };

  const updatRuleFunc = () => {
    let body = {
      driverRule: ruleData.driverRule,
      cargoOwnerRule: ruleData.cargoOwnerRule,
      operatorRule: ruleData.operatorRule,
    };
    postRequest(Api.CreateRule, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        getRule();
      }
    });
  };

  useEffect(() => {
    getRule();
  }, []);
  return (
    <Grid>
      <PageTitle title=" قوانین" />
      <Grid>
        <Grid>
          <h3>قوانین رانندگان</h3>
        </Grid>
        <TextareaAutosize
          value={ruleData.driverRule}
          maxRows={300}
          onChange={({ target }) =>
            setRuleData(prevDriverRule => ({
              ...prevDriverRule,
              driverRule: target.value,
            }))
          }
          style={{ width: "100%", padding: "10px", lineHeight: "30px" }}
          className={classes.formControl}
        />
      </Grid>

      <Grid>
        {" "}
        <Grid>
          <h3>قوانین کاربران</h3>
        </Grid>
        <TextareaAutosize
          value={ruleData.cargoOwnerRule}
          maxRows={300}
          onChange={({ target }) =>
            setRuleData(prevCargoOwnerRule => ({
              ...prevCargoOwnerRule,
              cargoOwnerRule: target.value,
            }))
          }
          style={{ width: "100%", padding: "10px", lineHeight: "30px" }}
          className={classes.formControl}
        />
      </Grid>
      <Grid>
        <Grid>
          <h3>قوانین متصدیان بار</h3>
        </Grid>

        <TextareaAutosize
          value={ruleData.operatorRule}
          maxRows={300}
          onChange={({ target }) =>
            setRuleData(prevOperatorRule => ({
              ...prevOperatorRule,
              operatorRule: target.value,
            }))
          }
          style={{ width: "100%", padding: "10px", lineHeight: "30px" }}
          className={classes.formControl}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        justifyContent="space-between"
        direction="row-reverse"
      >
        <Button
          variant="contained"
          style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
          onClick={updatRuleFunc}
        >
          ثبت{" "}
        </Button>
      </Grid>
    </Grid>
  );
};
export default Rule;
