import { Grid, Button } from "@material-ui/core";
import React, { useState } from "react";
import Select from "react-select";
import { TypeCustomer } from "../../constant/staticData";
import useStyles from "../../components/styles/styles";
import Input from "../../components/utils/Input";
import Api from "../../constant/Api";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../constant/ApiService";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle/PageTitle";

const NewComment = () => {
  const classes = useStyles();
  const history = useHistory();

  const [typeCustomer, setTypeCustomer] = useState();
  const [NewComment, setNewComment] = useState({
    firstName: "",
    lastName: "",
    title: "",
    description: "",
    userType: "",
  });
  const TypeCustomerChange = e => {
    setTypeCustomer(e.value);
  };
  const addNewCustomer = () => {
    if (!NewComment.description) {
      toast.error("توضیحات را وارد کنید");
      return;
    }
    if (!typeCustomer) {
      toast.error("نوع مشتری را وارد کنید");
      return;
    }
    let body = {
      firstName: NewComment.firstName,
      lastName: NewComment.lastName,
      title: NewComment.title,
      description: NewComment.description,
      userType: typeCustomer.value,
    };
    postRequest(Api.CreateComment, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/comments");
      }
    });
  };
  return (
    <Grid>
      <PageTitle title=" ثبت نظر" />
      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نام </label>
          <Input
            name="firstName"
            value={NewComment.firstName}
            onChange={({ target }) =>
              setNewComment(state => ({
                ...state,
                firstName: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>نام خانوادگی </label>
          <Input
            name="lastName"
            value={NewComment.lastName}
            onChange={({ target }) =>
              setNewComment(state => ({
                ...state,
                lastName: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>عنوان </label>
          <Input
            name="title"
            value={NewComment.title}
            onChange={({ target }) =>
              setNewComment(state => ({ ...state, title: target.value }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <label>توضیحات </label>
          <Input
            name="description"
            value={NewComment.description}
            onChange={({ target }) =>
              setNewComment(state => ({
                ...state,
                description: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نوع مشتری</label>
          <Select
            onChange={TypeCustomerChange}
            options={TypeCustomer.map(item => {
              return {
                value: item.value,
                label: item.label,
              };
            })}
            placeholder="نوع مشتری را انتخاب کنید"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        justifyContent="space-between"
        direction="row-reverse"
      >
        <Button
          variant="contained"
          style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
          onClick={addNewCustomer}
        >
          ثبت{" "}
        </Button>
      </Grid>
    </Grid>
  );
};
export default NewComment;
